import { FC } from "react"
import { TextField } from "./textField"

export const AutocompleteOffFields: FC = () => {
  return (
    <div style={{ display: "none" }}>
      <TextField
        type="text"
      />
      <TextField
        type="password"
      />
      <TextField
        type="password"
      />
    </div>
  )
}
