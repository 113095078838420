import { ReactPortal } from "../../../components/reactPortal"
import { Modal } from "../../../components/modal/modal"
import { useSelector, useDispatch } from "../../../hooks/redux"
import { CustomButton } from "../../../components/customButton"
import classes from "../../../styles/admin/adminModal.module.scss"
import ttsClasses from "../../../styles/tts/ttsModal.module.scss"
import ttsSidebarClasses from "../../../styles/tts/ttsSidebar.module.scss"
import { setModalVisibility, updateModalSettings } from "../../../store/slices/admin/adminSlice"
import { deleteUser } from "../../../store/slices/user/async"
import cn from "classnames"
import { UserSettings } from "../users/userSettings"


export const DeleteUserModal = () => {
  const dispatch = useDispatch()
  const show = useSelector((state) => state.admin.modal.type) === 'delete'
  const config = useSelector((state) => state.admin.modal.user)
  const changes = useSelector((state) => state.user.changes)
  const auth_token = useSelector((state) => state.user.user.auth_token)

  const handleCloseModal = () => {
    dispatch(setModalVisibility('none'))
    dispatch(updateModalSettings({
      auth_username: '',
      auth_token: '',
      access_console: false,
      access_asr: false,
      access_tts: false,
      access_admin: false
    }))
  }

  const removeUser = () => {
    try {
      dispatch(deleteUser({ auth_token, user_id: config.user_id }))
      // const {
      //   auth_token: token,
      //   auth_username: name,
      //   ...rest
      // } = modal.user;

      // const data = {
      //   token,
      //   name,
      //   auth_token,
      //   ...rest
      // }

      // const res = dispatch(deleteUser(data))
      handleCloseModal()

      // return res
    } catch (e) {
      // dispatch(addNotify({ text: `Не удалось добавить пользователя (${e})` }))
    }
  }

  return (
    <ReactPortal>
      <Modal
        classes={{ modalContent: cn(ttsClasses.ttsModal, ttsClasses.TtsModalSettings) }}
        title="Удаление пользователя"
        subtitle="Вы уверены, что хотите удалить пользователя?"
        isOpen={show}
        handleClose={handleCloseModal}
      >
        <div className={ttsClasses.ttsModalContent}>
          <UserSettings
            classes={{
              userInfo: classes.newUserInfo,
              userAccesses: classes.modalUserAccesses
            }}
            config={config}
            disabled />
          <div className={ttsClasses.ttsModalButtonGroup}>
            <CustomButton onClick={handleCloseModal}>Отмена</CustomButton>
            <CustomButton
              viewType='primary'
              className={classes.confirmationModalDeleteBtn}
              onClick={removeUser}
            >
              Удалить
            </CustomButton>
          </div>
        </div>
      </Modal>
    </ReactPortal>
  )
}
