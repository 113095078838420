import { ReactPortal } from "../../../components/reactPortal"
import { Modal } from "../../../components/modal/modal"
import { useSelector, useDispatch } from "../../../hooks/redux"
import { CustomButton } from "../../../components/customButton"
import classes from "../../../styles/admin/adminModal.module.scss"
import ttsClasses from "../../../styles/tts/ttsModal.module.scss"
import ttsSidebarClasses from "../../../styles/tts/ttsSidebar.module.scss"
import { setModalVisibility } from "../../../store/slices/admin/adminSlice"
import { resetUserChanges } from "../../../store/slices/user/userSlice"
import cn from "classnames"
import { deleteUser, updateUser } from "../../../store/slices/user/async"


export const ConfirmationModal = () => {
  const dispatch = useDispatch()
  const show = useSelector((state) => state.admin.modal.type === 'confirmation')
  const changes = useSelector((state) => state.user.changes)
  const user = useSelector((state) => state.user)
  const auth_token = useSelector((state) => state.user.user.auth_token)

  const handleCloseModal = () => {
    dispatch(setModalVisibility('none'))
  }

  const handleConfirmChanges = () => {
    changes.forEach(id => {
      const foundedUser = user.users.find(item => item.user_id === id)

      if (foundedUser && foundedUser.auth_token && foundedUser.auth_username) {
        const data = {
          token: foundedUser.auth_token,
          name: foundedUser.auth_username,
          ...foundedUser,
          auth_token,
        }

        setTimeout(() => dispatch(updateUser(data)), 100);
      }
    }
    )
    dispatch(resetUserChanges())
    handleCloseModal()
  }

  return (
    <ReactPortal>
      <Modal
        classes={{ modalContent: cn(ttsClasses.ttsModal, ttsClasses.TtsModalSettings) }}
        title="Подтвердите изменения"
        subtitle="Вы уверены, что хотите сохранить внесённые изменения?"
        isOpen={show}
        handleClose={handleCloseModal}
      >
        {/* <div className={classes.confirmationModalInfo}>
          <span>Удаленно: {changes.deletedUsers.length}</span>
          <span>Измененно: {changes.changedUsers.length}</span>
        </div> */}
        <div className={ttsClasses.ttsModalContent}>
          <div className={ttsClasses.ttsModalButtonGroup}>
            <CustomButton onClick={handleCloseModal}>Отмена</CustomButton>
            <CustomButton
              viewType='primary'
              onClick={handleConfirmChanges}
            >
              Применить
            </CustomButton>
          </div>
        </div>
      </Modal>
    </ReactPortal>
  )
}
