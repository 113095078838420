import { useEffect, useState } from 'react'
import { Pagination } from '../../components/pagination'
import { OptionButtons } from '../../components/optionButtons'
import { CustomSelect } from '../../components/customSelect'
import { CustomMultiSelect } from '../../components/customMultiSelect'
import { CustomButton } from '../../components/customButton'
import { useSelector, useDispatch } from '../../hooks/redux'
import {
    updateConsoleMode,
    updateSessionCount,
    resetFiltersState,
    setStartDate,
    setEndDate,
    setErrorText,
    setConsoleError,
    setConsoleLoading,
    setSelectedUsers
} from '../../store/slices/statisticsConsole/consoleSlice'
import { DateRangePicker } from '../../components/dateRangePicker/dateRangePicker'
import classes from '../../styles/statisticsConsole/filterHeader.module.scss'
import { consoleSend, createAudioArchive } from '../../store/slices/statisticsConsole/async'
import { getUsers } from '../../store/slices/user/async'
import { formatSessions } from '../../functions/common'
import { formatTime, formatDateForRequest } from '../../functions/date'
import { CustomIcon } from '../../components/customIcon/customIcon'


const sessionsConfig = [
    {
        name: '5 сессий',
        value: 5
    },
    {
        name: '10 сессий',
        value: 10
    },
    {
        name: '50 сессий',
        value: 50
    },
    {
        name: '100 сессий',
        value: 100
    },
    {
        name: '500 сессий',
        value: 500
    },
    {
        name: '1000 сессий',
        value: 1000
    }
]

export const FilterHeader = () => {
    const dispatch = useDispatch()
    const consoleMode = useSelector((state) => state.console.consoleMode)
    const sessionsCount = useSelector((state) => state.console.sessionsCount)
    const startDate = useSelector((state) => state.console.startDate)
    const endDate = useSelector((state) => state.console.endDate)
    const user = useSelector((state) => state.user.user)
    const users = useSelector((state) => state.user.users)?.map(item => item.auth_username)
    const authToken = useSelector((state) => state.user.user.auth_token)
    const stats = useSelector((state) => state.console.stats)
    const selectedSessionIds = useSelector((state) => state.console.selectedSessionIds)
    const sessions = useSelector((state) => state.console.sessions)
    const selectedUsers = useSelector((state) => state.console.selectedUsers)
    const [currentPage, setCurrentPage] = useState<number>(1)

    useEffect(() => {
        if (!users?.length && user.access_admin) {
            dispatch(getUsers(authToken))
        }
    }, [authToken, dispatch])

    const toggleConsoleMode = () => {
        dispatch(updateConsoleMode(consoleMode === 'asr' ? 'tts' : 'asr'))
    }

    const setSessionCount = (count: number | string) => {
        dispatch(updateSessionCount(+count))
    }

    const handleStartDateChange = (startDate: Date | null) => {
        dispatch(setStartDate(startDate))
    }

    const handleEndDateChange = (endDate: Date | null) => {
        dispatch(setEndDate(endDate))
    }

    const handleUsersChange = (users: string[]) => {
        dispatch(setSelectedUsers(users))
    }

    const resetAllFilters = () => {
        dispatch(resetFiltersState())
        dispatch(getUsers(authToken))
        dispatch(setConsoleError(false))
        setCurrentPage(1)
    }

    const sendConsoleData = async (offset: number) => {
        try {
            const data = {
                auth_token: authToken,
                clients: selectedUsers,
                interval: [
                    (startDate && formatDateForRequest(startDate)) || '',
                    (endDate && formatDateForRequest(endDate)) || ''
                ],
                limit: sessionsCount,
                offset,
                service_type: consoleMode
            }

            const res = dispatch(consoleSend(data))

            return res

        } catch (e) {
            dispatch(setErrorText(`${e}`))
            dispatch(setConsoleError(true))
        }

        setCurrentPage(1)
    }

    const downloadSessions = () => {
        dispatch(setConsoleLoading(true))
        createAudioArchive({
            audioIds: selectedSessionIds.length > 0 ? selectedSessionIds : sessions.map(item => item.id),
            serviceType: stats?.service_type || 'asr',
            authToken
        })
        dispatch(setConsoleLoading(false))
    }

    const handlePageChange = (pageNumber: number) => {
        setCurrentPage(pageNumber)
        sendConsoleData((pageNumber * sessionsCount) - sessionsCount)
    }

    return (
        <div className={classes.consoleHeader}>
            <div className={classes.consoleFilters}>
                <OptionButtons
                    classes={{ buttonWrapper: classes.toggleConsoleModeButton }}
                    buttons={[
                        { name: 'ASR', value: 0 },
                        { name: 'TTS', value: 1 },
                    ]}
                    initialButtonValue={consoleMode === 'asr' ? { name: 'ASR', value: 0 } : { name: 'TTS', value: 1 }}
                    handleClick={toggleConsoleMode}
                />
                <CustomMultiSelect
                    classes={{ selectorWrapperClassName: classes.usersSelect }}
                    localeText='Выберите пользователей'
                    items={users && users.length > 1 ? users : [user.auth_username]}
                    selectedItems={users.length > 1 ? selectedUsers : [user.auth_username]}
                    handleChange={handleUsersChange}
                />
                <DateRangePicker
                    localeText='Выберите период'
                    startDate={startDate || null}
                    endDate={endDate || null}
                    onChangeStartDate={handleStartDateChange}
                    onChangeEndDate={handleEndDateChange}
                />
                <CustomSelect
                    classes={{ selectorWrapperClassName: classes.sessionSelect }}
                    items={sessionsConfig}
                    initialValue={{
                        name: `${sessionsCount} сессий`,
                        value: sessionsCount,
                    }}
                    handleChange={(option) => setSessionCount(option.value)}
                />
                <CustomButton
                    onClick={resetAllFilters}
                    className={classes.resetButton}>
                    Сбросить
                </CustomButton>
                <CustomButton disabled={selectedUsers.length === 0} onClick={() => sendConsoleData(0)} className={classes.confirmButton}>Применить</CustomButton>
            </div>
            {stats?.sessions_count
                ? (
                    <div className={classes.consoleStats}>
                        <button onClick={() => downloadSessions()} className={classes.consoleDownloadBtn}><CustomIcon icon='download' />Скачать {selectedSessionIds.length ? `выбранные (${selectedSessionIds.length})` : 'все'}</button>
                        <div className={classes.consoleStatsItems}>
                            <span className={classes.consoleStatsItem}>{formatSessions(stats?.sessions_count)}</span>
                            {
                                stats.service_type === 'asr'
                                    ? <>
                                        <span className={classes.consoleStatsItem}><CustomIcon icon='cloackIcon' /> {formatTime(stats.asr?.total_duration || 0)}</span>
                                        <span className={classes.consoleStatsItem}><CustomIcon icon='shearsIcon' /> {stats.asr?.total_15_sec_pieces} отрезков по 15 секунд</span>
                                    </>
                                    : <span className={classes.consoleStatsItem}><CustomIcon icon='penIcon' /> {stats.tts?.total_length} Символов</span>
                            }
                            {Math.ceil(stats?.sessions_count / stats?.limit) > 1 && <Pagination currentPage={currentPage} totalPages={Math.ceil(stats.sessions_count / stats?.limit)} onPageChange={handlePageChange} />}
                        </div>
                    </div>
                )
                : ''
            }
        </div>
    )
}
