import { FC } from "react"
import { useDispatch, useSelector } from "../../../hooks/redux"
import { setModalVisibility, updateSelectedUsersList, updateModalSettings } from "../../../store/slices/admin/adminSlice"
import { Checkbox } from "../../../components/checkbox/checkbox"
import { CustomButton } from "../../../components/customButton"
import { CustomIcon } from "../../../components/customIcon/customIcon"
import classes from '../../../styles/admin/usersList.module.scss'
import { IUser, PartialUser } from "../../../@types/user"
import { UserSettings } from "./userSettings"
import { updateUserSettings } from "../../../store/slices/user/userSlice"
import { PortalTooltip } from "../../../components/tooltip/portalTooltip"
import { Link } from "react-router-dom"
import { setSelectedUsers } from "../../../store/slices/statisticsConsole/consoleSlice"


export const UsersListItem: FC<{
    config: IUser,
    isSelected: boolean
}> = ({
    config,
    isSelected
}) => {
        const dispatch = useDispatch()
        const consoleSelectedUsers = useSelector((state) => state.console.selectedUsers)
        const adminSelectedUsers = useSelector((state) => state.admin.selectedUsers)
        const changedUsers = useSelector((state) => state.user.changes)
        const users = useSelector((state) => state.user.users)

        const handleChangeSettings = (permissions: PartialUser) => {
            dispatch(updateUserSettings({ ...permissions, user_id: config.user_id }))
        }

        const handleRemoveUser = () => {
            dispatch(setModalVisibility('delete'))
            dispatch(updateModalSettings(config))
        }

        const selectUser = () => {
            dispatch(updateSelectedUsersList(config.user_id))
        }

        const handleRedirect = () => {
            dispatch(setSelectedUsers(consoleSelectedUsers))

            if (adminSelectedUsers.includes(config.user_id)) {
                dispatch(setSelectedUsers(adminSelectedUsers.map(uId => users.find(user => user.user_id === uId)?.auth_username || '')))
            } else {
                dispatch(setSelectedUsers([config.auth_username]))
            }
        }

        return (
            <div className={classes.userSettings}>
                {changedUsers.find(id => id === config.user_id) && <span className={classes.changeMarker}></span>}
                <Checkbox isChecked={isSelected} handleCheckboxChange={selectUser} />
                <PortalTooltip placement="top-middle" tooltipContent="Перейти в консоль">
                    <CustomButton
                        onClick={handleRedirect}
                        className={classes.redirectBtn}
                    >
                        <Link to="/console"><CustomIcon icon="back" /></Link>
                    </CustomButton>
                </PortalTooltip>
                <UserSettings
                    handleChangeSettings={handleChangeSettings}
                    config={config} />
                <CustomButton
                    onClick={handleRemoveUser}
                    className={classes.deleteUserBtn}
                >
                    <CustomIcon icon="delete" />
                </CustomButton>
            </div>
        )
    }
