import { ChangeEvent, FC, memo, useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import cn from 'classnames'
import { ReactPortal } from '../../../components/reactPortal'
import { Modal } from '../../../components/modal/modal'
import { CustomButton } from '../../../components/customButton'
import { useSelector } from '../../../hooks/redux'
import classes from '../../../styles/tts/ttsModal.module.scss'
import sidebar from '../../../styles/tts/ttsSidebar.module.scss'
import modalClasses from '../../../styles/mobilePopup.module.scss'
import { ttsUpdateSettings, ttsSetUpdateCache } from '../../../store/slices/tts/ttsSlice'
import { TTtsSettings, TTtsSettingsNoCache } from '../../../@types/tts'
import { useMediaQuery } from 'react-responsive'
import { MobilePopup } from '../../../components/mobilePopup'
import { ttsSettingsConfig } from '../config'
import { SwitchCheckbox } from '../../../components/checkbox/switchCheckbox'


const InputRange: FC<{
  min: number
  max: number
  step: number
  handleChange: (e: ChangeEvent<HTMLInputElement>) => void
  value: number
  name: string
}> = ({ min, max, step, handleChange, value, name }) => {
  const [parentWidth, setParentWidth] = useState<number>(0)

  useEffect(() => {
    const handleResize = () => {
      const parentContainer = document.getElementById('inputRange')
      if (parentContainer) {
        setParentWidth(parentContainer.offsetWidth)
      }
    }

    handleResize()

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [parentWidth])

  const calculateTranslateX = (value: number, min: number, max: number) => {
    const percentage = ((value - min) / (max - min)) * 100
    const currentOffset = ((parentWidth - 40) / 100) * percentage + 50
    return `${currentOffset}px`
  }

  return (
    <div className={classes.inputRange}>
      <div className={classes.ttsModalSettingInput}>
        <span
          style={{
            transform: `translate(${calculateTranslateX(value, min, max)}, 0px)`,
          }}
          className={classes.inputRangeValue}
        >
          {value}
        </span>
        <input
          style={{
            background: `linear-gradient(to right, #013b8e ${((value - min) / (max - min)) * 100}%, #f2f3f5 ${((value - min) / (max - min)) * 100
              }%)`,
          }}
          id="inputRange"
          onChange={handleChange}
          value={value}
          name={name}
          step={step}
          min={min}
          max={max}
          type="range"
        />
      </div>
    </div>
  )
}

const TtsSettingsContent: FC<{
  ttsLocalSettings: TTtsSettings
  handleChange: (e: ChangeEvent<HTMLInputElement>) => void
}> = ({ ttsLocalSettings, handleChange }) => {
  return (
    <div className={classes.ttsModalSettingsWrapper}>
      {ttsSettingsConfig.map(({ name, value, min, max, step }, index) => {
        const val = ttsLocalSettings[value as keyof TTtsSettingsNoCache]
        return (
          <div className={classes.ttsModalSettingsItem} key={name + index + value}>
            <span className={classes.ttsFilterLineTitle}>{name}</span>
            <InputRange
              name={value}
              value={val}
              max={max}
              min={min}
              step={step}
              key={name + value}
              handleChange={handleChange}
            />
          </div>
        )
      })}
    </div>
  )
}

export const TtsModalSettings: FC<{ showPopup: boolean; handleClose: () => void }> = memo(
  ({ handleClose, showPopup }) => {
    const dispatch = useDispatch()
    const ttsSettings = useSelector((state) => state.tts.settings)
    const [ttsLocalSettings, setTtsLocalSettings] = useState({
      ...ttsSettings,
    })

    const isLaptopOrMobile = useMediaQuery({ query: '(max-width: 768px)' })

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
      const currentValue = +e.target.value
      const name = e.target.name
      setTtsLocalSettings({ ...ttsLocalSettings, [name]: +currentValue })
    }

    const confirmChanges = () => {
      dispatch(ttsUpdateSettings(ttsLocalSettings))
      handleClose()
    }

    const closeModal = () => {
      handleClose()
      setTtsLocalSettings({ ...ttsSettings })
    }

    const toggleUpdateCache = () => {
      setTtsLocalSettings({ ...ttsLocalSettings, update_cache: ttsLocalSettings.update_cache ? 0 : 1 })
    }

    return !isLaptopOrMobile ? (
      <ReactPortal>
        <Modal
          classes={{ modalContent: cn(classes.ttsModal, classes.TtsModalSettings) }}
          title="Настройки"
          isOpen={showPopup}
          handleClose={closeModal}
        >
          <div className={classes.ttsModalContent}>
            <TtsSettingsContent ttsLocalSettings={ttsLocalSettings} handleChange={handleChange} />
            <div className={classes.ttsSettingsFooter}>
              <div className={classes.ttsUpdateCacheCheckbox}>
                <SwitchCheckbox
                  name='update_cache'
                  title='Обновить кэш'
                  checked={!!ttsLocalSettings.update_cache}
                  onChange={toggleUpdateCache}
                />
              </div>
              <div className={classes.ttsModalButtonGroup}>
                <CustomButton onClick={closeModal}>Отмена</CustomButton>
                <CustomButton viewType='primary' onClick={confirmChanges}>
                  Сохранить
                </CustomButton>
              </div>
            </div>
          </div>
        </Modal>
      </ReactPortal>
    ) : (
      <MobilePopup show={showPopup} handleClose={closeModal}>
        <div className={modalClasses.modalPopupTitleWrapper}>
          <div className={modalClasses.mobilePopupTitle}>Настройки</div>
          <button onClick={confirmChanges} className={modalClasses.mobilePopupCloseBtn}>
            Сохранить
          </button>
        </div>
        <TtsSettingsContent ttsLocalSettings={ttsLocalSettings} handleChange={handleChange} />
        <div className={classes.ttsUpdateCacheCheckbox}>
          <SwitchCheckbox
            name='update_cache'
            title='Обновить кэш'
            checked={!!ttsSettings.update_cache}
            onChange={toggleUpdateCache}
          />
        </div>
      </MobilePopup>
    )
  }
)

TtsModalSettings.displayName = 'TtsModalSettings'
