import { Drawer } from '../../components/drawer'
import classes from '../../styles/statisticsConsole/consoleDrawer.module.scss'
import cn from 'classnames'
import { FC } from 'react'
import { consoleSetAudio } from '../../store/slices/statisticsConsole/consoleSlice'
import { ConsoleDrawerProps } from '../../@types/console'
import { PlayerV2, THandlePlayerChangeProps } from '../../components/player/playerV2'
import sessionsClasses from '../../styles/statisticsConsole/console.module.scss'
import { useDispatch } from '../../hooks/redux'
import { useCallback } from 'react'
import { CustomIcon } from '../../components/customIcon/customIcon'
import { copyText } from '../../functions/common'
import { formatDateString } from '../../functions/date'


export const ConsoleDrawer: FC<ConsoleDrawerProps> = ({
    isOpen,
    onClose,
    config,
    sessionId,
    username,
    voice,
    confidenceClass,
    audioUrl,
    serviceType,
    sampleRate
}) => {
    const dispatch = useDispatch()
    const { id, text, confidence, channel, timestamp, normalized_text } = config
    
    const handlerCopyText = async () => {
        const textToCopy = sessionId
        await copyText(textToCopy)
    }

    const handleAudioChange = useCallback(
        ({ currentTime }: THandlePlayerChangeProps) => {
            dispatch(consoleSetAudio({ currentTime, id }))
        },
        [dispatch, id]
    )

    const handlePlay = (waveSurfer: WaveSurfer) => {
        const totalTime = waveSurfer.current?.getDuration()
        const currentTime = waveSurfer.current?.getCurrentTime()

        dispatch(consoleSetAudio({ currentTime, totalTime, id }))
    }

    return (
        <Drawer isOpen={isOpen} onClose={onClose}>
            <div className={classes.consoleDrawerContainer}>
                <div className={classes.consoleDrawerContent}>
                    <span className={classes.drawerTittle}>{serviceType.toUpperCase() + '-запись #' + id}</span>
                    <button className={classes.drawerCloseButton} onClick={onClose}><CustomIcon icon='cross' /></button>
                </div>
                <div className={classes.consoleDrawerContent}>
                    <div style={{ gridTemplateColumns: '1fr' }} className={sessionsClasses.sessionsAudio}>
                        <PlayerV2
                            waveColor='#014EBC33'
                            primaryColor='#014EBC'
                            handleOnSeek={handleAudioChange}
                            handleChange={handleAudioChange}
                            handlePlay={handlePlay}
                            barWidth={3}
                            url={audioUrl}
                            classes={{
                                volumeBtn: sessionsClasses.consoleVolumeBtn,
                                playPauseBtn: sessionsClasses.consolePlayPauseBtn,
                                playerTime: sessionsClasses.consolePlayerTime
                            }}
                        />
                    </div>
                </div>
                {serviceType === 'asr'
                    ? <>
                        <div className={classes.consoleDrawerContent}>
                            <div className={classes.consoleDrawerContentIntem}>
                                <span className={classes.textKey}>Пользователь</span>
                                <span className={classes.textValue}>{username}</span>
                            </div>
                            <div className={classes.consoleDrawerContentIntem}>
                                <span className={classes.textKey}>ID сессии</span>
                                <span className={classes.textValue}>
                                    <span onClick={handlerCopyText} className={classes.copyTextIcon}><CustomIcon icon='copy' /></span>
                                    {sessionId}
                                </span>
                            </div>
                            <div className={classes.consoleDrawerContentIntem}>
                                <span className={classes.textKey}>Уверенность</span>
                                <span className={classes.textValue}>
                                    <span className={cn(sessionsClasses.recordConfidence, confidenceClass)}>
                                        {Math.round(confidence)}
                                        <CustomIcon icon="confidence" />
                                    </span>
                                </span>
                            </div>
                        </div>
                        <div className={classes.consoleDrawerContent}>
                            <div className={classes.consoleDrawerContentIntem}>
                                <span className={classes.textKey}>Канал</span>
                                <span className={classes.textValue}>{channel}</span>
                            </div>
                            <div className={classes.consoleDrawerContentIntem}>
                                <span className={classes.textKey}>Время обращения</span>
                                <span className={classes.textValue}>{formatDateString(timestamp)}</span>
                            </div>
                            <div className={classes.consoleDrawerContentIntem}>
                                <span className={classes.textKey}>Частота дискретизации</span>
                                <span className={classes.textValue}>{sampleRate}</span>
                            </div>
                        </div>
                        <div className={classes.consoleDrawerContent}>
                            <div className={classes.consoleDrawerContentIntem}>
                                <span className={classes.textKey}>Текст распознавания</span>
                            </div>
                            <div className={cn(classes.textValue, classes.consoleDrawerTextarea)}>{text}</div>
                        </div>
                    </>
                    : <>
                        <div className={classes.consoleDrawerContent}>
                            <div className={classes.consoleDrawerContentIntem}>
                                <span className={classes.textKey}>Голос</span>
                                <span className={classes.textValue}>{voice}</span>
                            </div>
                            <div className={classes.consoleDrawerContentIntem}>
                                <span className={classes.textKey}>ID сессии</span>
                                <span className={classes.textValue}>
                                    <span onClick={handlerCopyText} className={classes.copyTextIcon}><CustomIcon icon='copy' /></span>
                                    {sessionId}
                                </span>
                            </div>
                            <div className={classes.consoleDrawerContentIntem}>
                                <span className={classes.textKey}>Пользователь</span>
                                <span className={classes.textValue}>{username}</span>
                            </div>
                            <div className={classes.consoleDrawerContentIntem}>
                                <span className={classes.textKey}>Время обращения</span>
                                <span className={classes.textValue}>{formatDateString(timestamp)}</span>
                            </div>
                        </div>
                        <div className={classes.consoleDrawerContent}>
                            <div className={classes.consoleDrawerContentIntem}>
                                <span className={classes.textKey}>Нормализованный текст</span>
                            </div>
                            <div className={cn(classes.textValue, classes.consoleDrawerTextarea)}>{normalized_text}</div>
                        </div>
                    </>}
            </div>
        </Drawer>
    )
}
