import { ReactPortal } from '../../../components/reactPortal'
import { Modal } from '../../../components/modal/modal'
import { CustomButton } from '../../../components/customButton'
import { FC, memo } from 'react'
import { useMediaQuery } from 'react-responsive'
import { IVoice } from '../../../@types/tts'
import { useSelector } from '../../../hooks/redux'
import classes from '../../../styles/tts/ttsModal.module.scss'
import modalClasses from '../../../styles/mobilePopup.module.scss'
import { MobilePopup } from '../../../components/mobilePopup'
import { TtsModalFilters } from '../ttsFilters/ttsModalFilters'
import { ModalVoices } from './ttsModalVoices'

export const TtsModal: FC<{
  showModal: boolean
  handleClose: () => void
  prevVoices: IVoice[]
  confirmChanges: () => void
}> = memo(({ handleClose, showModal, prevVoices, confirmChanges }) => {
  const voices = useSelector((state) => state.tts.voices)
  const pickedVoices = voices.filter((item) => item.picked).length
  const pickedPrevVoices = prevVoices.filter((item) => item.picked).length
  const qtyNewVoices = pickedVoices - pickedPrevVoices
  const isLaptopOrMobile = useMediaQuery({ query: '(max-width: 768px)' })
  
  return !isLaptopOrMobile ? (
    <ReactPortal>
      <Modal
        classes={{ modalContent: classes.ttsModal }}
        title="Выбор голосов"
        subtitle="Вы можете прослушать наших дикторов и выбрать несколько голосов сразу"
        isOpen={showModal}
        handleClose={!isLaptopOrMobile ? handleClose : confirmChanges}
      >
        <div className={classes.ttsModalContent}>
          <TtsModalFilters />
          <ModalVoices />
          <div className={classes.ttsModalButtonGroup}>
            <CustomButton onClick={handleClose}>Отмена</CustomButton>
            <CustomButton onClick={confirmChanges} viewType='primary'>
              Добавить голоса {qtyNewVoices > 0 ? `(${qtyNewVoices})` : ''}
            </CustomButton>
          </div>
        </div>
      </Modal>
    </ReactPortal>
  ) : (
    <MobilePopup show={showModal} handleClose={handleClose}>
      <div className={modalClasses.modalPopupTitleWrapper}>
        <div className={modalClasses.mobilePopupTitle}>Выбор голосов</div>
        <button onClick={confirmChanges} className={modalClasses.mobilePopupCloseBtn}>
          Добавить голоса
        </button>
      </div>
      <div className={classes.ttsModalContent}>
        <TtsModalFilters />
        <ModalVoices />
      </div>
    </MobilePopup>
  )
})

TtsModal.displayName = 'TtsModalVoices'
