import cn from 'classnames'
import { FC, ReactNode, ButtonHTMLAttributes } from 'react'
import classes from '../styles/customButton.module.scss'

export interface ICustomButton extends ButtonHTMLAttributes<HTMLButtonElement> {
  children: ReactNode
  viewType?: 'outline' | 'default' | 'primary'
}

export const CustomButton: FC<ICustomButton> = ({ children, viewType, className, ...rest }) => {
  return (
    <button
      {...rest}
      className={cn(
        className, 
        classes.customButton, 
        viewType === 'outline' && classes.customButtonOutline,
        viewType === 'primary' && classes.primaryBtn
      )}
    >
      {children}
    </button>
  )
}
