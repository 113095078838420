import { FC, memo, useState, useEffect, useRef } from 'react'
import { CustomIcon } from './customIcon/customIcon'
import customMultiSelectClasses from '../styles/customMultiSelect.module.scss'
import { OutClick } from './outClick'
import cn from 'classnames'
import { Checkbox } from './checkbox/checkbox'

const ITEM_HEIGHT = 39
const MAX_ITEMS = 5

export const CustomMultiSelect: FC<{
  items: string[]
  selectedItems: string[]
  localeText?: string
  handleChange: (options: string[]) => void
  classes?: {
    outClickClassName?: string
    selectorWrapperClassName?: string
    selectListItemsClassName?: string
    selectItem?: string
  }
}> = memo(({ items, selectedItems, handleChange, classes, localeText }) => {
  const [open, setOpen] = useState(false)
  const [selectAll, setSelectAll] = useState(false)
  const [scrollPosition, setScrollPosition] = useState(0)
  const listRef = useRef<HTMLDivElement>(null)

  const handleCloseOpen = () => {
    setOpen((p) => !p)
  }

  const handleClickItem = (item: string) => {
    handleChange(
      selectedItems.includes(item)
        ? selectedItems.filter((i) => i !== item)
        : [...selectedItems, item]
    )
  }

  const handleSelectAll = (e: any) => {
    e.stopPropagation()
    if (selectAll) {
      handleChange([])
      setSelectAll(false)
    } else {
      handleChange(items)
      setSelectAll(true)
    }
  }

  useEffect(() => {
    setSelectAll(items.length > 0 && selectedItems.length === items.length)
  }, [selectedItems, items])

  useEffect(() => {
    if (open && listRef.current) {
      listRef.current.scrollTop = scrollPosition
    }
  }, [open, scrollPosition])

  const handleScroll = () => {
    if (listRef.current) {
      setScrollPosition(listRef.current.scrollTop)
    }
  }

  return (
    <OutClick
      className={cn(customMultiSelectClasses.outClickWrapper, classes?.outClickClassName && classes.outClickClassName)}
      onClick={open ? handleCloseOpen : () => null}
    >
      <>
        <div
          className={cn(customMultiSelectClasses.customSelectWrapper, classes?.selectorWrapperClassName)}
          onClick={items.length > 1 ? handleCloseOpen : () => {}}
        >
          <div className={customMultiSelectClasses.customSelectActive}>
            {selectedItems.length > 0 ? (
              <span className={customMultiSelectClasses.multiSelectPreview}>{selectedItems.map((item) => item).join(', ')}</span>
            ) : (
              <span style={{ color: '#808a9a' }}>{localeText}</span>
            )}
            {open ? (
              <span style={{ marginRight: 10 }} onClick={handleSelectAll}>
                <Checkbox isChecked={selectAll} handleCheckboxChange={() => {}} />
              </span>
            ) : (
              <CustomIcon icon="arrowRight" />
            )}
          </div>
        </div>
        {open && (
          <div
            ref={listRef}
            onScroll={handleScroll}
            style={{ maxHeight: MAX_ITEMS * ITEM_HEIGHT }}
            className={cn(
              customMultiSelectClasses.customSelectListItems,
              classes?.selectListItemsClassName && classes?.selectListItemsClassName,
              open && customMultiSelectClasses.customSelectorOpened
            )}
          >
            {items.map((item, index) => (
              <div
                key={item + index}
                className={cn(customMultiSelectClasses.customMultiSelectItem)}
                onClick={() => handleClickItem(item)}
              >
                <span>{item}</span>
                <Checkbox
                  isChecked={selectAll || selectedItems.includes(item)}
                  handleCheckboxChange={() => {}}
                />
              </div>
            ))}
          </div>
        )}
      </>
    </OutClick>
  )
})
