import { useRef, useMemo, ChangeEvent } from 'react'
import { SearchField } from '../../components/textField/searchField'
import classes from '../../styles/admin/adminHeader.module.scss'
import asrClasses from '../../styles/filter.module.scss'
import consoleClasses from '../../styles/statisticsConsole/filterHeader.module.scss'
import { useSelector, useDispatch } from '../../hooks/redux'
import { 
    updateSearchString, 
    setModalVisibility
} from '../../store/slices/admin/adminSlice'
import { CustomButtonSelect } from '../../components/customButtonSelect'
import { PortalTooltip } from '../../components/tooltip/portalTooltip'
import { CustomIcon } from '../../components/customIcon/customIcon'
import { CustomButton } from '../../components/customButton'
import { AdminFilters } from './adminFilters'
import { setInitialUsersState } from '../../store/slices/user/userSlice'
import cn from 'classnames'
import { TAdminModal } from '../../@types/admin'


export const AdminHeader = () => {
    const dispatch = useDispatch()
    const filters = useSelector((state) => state.admin.filters)
    const changes = useSelector((state) => state.user.changes)
    // const showConfirmationWindow = useSelector((state) => state.admin.showConfirmationWindow)

    const isChanged = changes.length > 0

    const initialFilters = useRef(filters.accessConfig).current
    const filtersChanged = useMemo(() => {
      return JSON.stringify(filters.accessConfig) !== JSON.stringify(initialFilters)
    }, [filters.accessConfig])

    const handleChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
        dispatch(updateSearchString(e.target.value))
    }

    const handleClearInput = () => {
        dispatch(updateSearchString(''))
    }

    const handleShowModal = (type: TAdminModal) => {
        dispatch(setModalVisibility(type))
    }

    const resetChanges = () => {
        dispatch(setInitialUsersState())
    }

    return (
        <div className={classes.adminHeaderWrapper}>
            <SearchField
                localeText='Имя пользователя / токен'
                searchString={filters.searchString}
                handleChangeInput={handleChangeInput}
                handleClearInput={handleClearInput}
            />
            <CustomButtonSelect
                classes={{
                    content: classes.adminFilterButtonContent,
                    button: asrClasses.filterBtn,
                }}
                active={filtersChanged}
                contentComponent={<AdminFilters />}
            >
                <PortalTooltip placement="top-middle" tooltipContent="Фильтры">
                    <div
                        className={asrClasses.tooltipFilterClassName}
                    >
                        <CustomIcon icon={filtersChanged ? 'changedFilters' : 'filter'} />
                    </div>
                </PortalTooltip>
            </CustomButtonSelect>
            <PortalTooltip placement="top-middle" tooltipContent="Добавить пользователя">
                <CustomButton onClick={() => handleShowModal('user')} className={classes.adminAddUserButton}>
                    <CustomIcon icon='addUser' />
                </CustomButton>
            </PortalTooltip>
            <CustomButton
                onClick={resetChanges}
                className={consoleClasses.resetButton}>
                Сбросить
            </CustomButton>
            <CustomButton
                disabled={!isChanged}
                onClick={() => handleShowModal('confirmation')}
                className={cn(consoleClasses.confirmButton, !isChanged && classes.disabled)}>
                Применить
            </CustomButton>
        </div>
    )
}
