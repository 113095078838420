import { TextField } from "../../../components/textField/textField"
import { CustomButton } from "../../../components/customButton"
import { CustomIcon } from "../../../components/customIcon/customIcon"
import { PartialUser } from "../../../@types/user"
import classes from "../../../styles/admin/usersList.module.scss"
import cn from "classnames"
import { FC } from "react"


export const UserSettings: FC<{
    config: PartialUser,
    error?: boolean
    classes?: {
        userInfo?: string,
        userAccesses?: string
    },
    disabled?: boolean,
    handleChangeSettings?: (permissions: PartialUser) => void
}> = ({
    config,
    classes: customClasses,
    disabled,
    error,
    handleChangeSettings = () => { }
}) => {
        const {
            auth_username,
            auth_token,
            access_asr,
            access_tts,
            access_console,
            access_admin
        } = config

        return (
            <>
                <div className={cn(classes.userInfo, customClasses?.userInfo)}>
                    <TextField
                        localeText="Имя пользователя"
                        value={auth_username}
                        disabled={disabled}
                        error={error}
                        type="text"
                        handleChangeInput={(e) => handleChangeSettings({ auth_username: e.target.value })}
                    />
                    <TextField
                        localeText="Токен"
                        type="password"
                        disabled={disabled}
                        value={auth_token}
                        handleChangeInput={(e) => handleChangeSettings({ auth_token: e.target.value })}
                    />
                </div>
                <div className={cn(classes.userAccesses, customClasses?.userAccesses)}>
                    <CustomButton
                        disabled={disabled}
                        onClick={() => handleChangeSettings({ access_asr: !access_asr })}
                        className={cn(classes.accessButton, access_asr ? classes.active : classes.disabled)}
                        style={{ alignSelf: 'flex-end', justifyContent: 'center' }}
                    >
                        <CustomIcon icon="asrLogo" /> ASR
                    </CustomButton>
                    <CustomButton
                        disabled={disabled}
                        onClick={() => handleChangeSettings({ access_tts: !access_tts })}
                        className={cn(classes.accessButton, access_tts ? classes.active : classes.disabled)}
                        style={{ alignSelf: 'flex-end', justifyContent: 'center' }}
                    >
                        <CustomIcon icon="ttsLogo" /> TTS
                    </CustomButton>
                    <CustomButton
                        disabled={disabled}
                        onClick={() => handleChangeSettings({ access_console: !access_console })}
                        className={cn(classes.accessButton, access_console ? classes.active : classes.disabled)}
                        style={{ alignSelf: 'flex-end', justifyContent: 'center' }}
                    >
                        <CustomIcon icon="consoleLogo" /> Консоль
                    </CustomButton>
                    <CustomButton
                        disabled={disabled}
                        onClick={() => handleChangeSettings({ access_admin: !access_admin })}
                        className={cn(classes.accessButton, access_admin ? classes.active : classes.disabled)}
                        style={{ alignSelf: 'flex-end', justifyContent: 'center' }}
                    >
                        <CustomIcon icon="settings" /> Админка
                    </CustomButton>
                </div>
            </>
        )
    }
