import { Dispatch, FC, memo, SetStateAction, useEffect, useMemo, useState } from 'react'
import { useMediaQuery } from 'react-responsive'
import cn from 'classnames'
import { CustomSelect } from '../../../components/customSelect'
import { OptionButtons } from '../../../components/optionButtons'
import classes from '../../../styles/asr/asrSidebar.module.scss'
import { getAsrConfig } from '../../../store/slices/asr/async'
import { useSelector, useDispatch } from '../../../hooks/redux'
import { asrUpdateSettings } from '../../../store/slices/asr/asrSlice'
import { additionalAsrConfig, languageTranslate } from './config'
import { CustomIcon } from '../../../components/customIcon/customIcon'
import { Loader } from '../../../components/loader'
import { setLocalStorageInfo } from '../../../tools'
import { PortalTooltip } from '../../../components/tooltip/portalTooltip'
import { SwitchCheckbox } from '../../../components/checkbox/switchCheckbox'


const AsrAdditionalSettings: FC<{ openModal: boolean; setOpenModal: Dispatch<SetStateAction<boolean>> }> = memo(
  ({ openModal, setOpenModal }) => {
    const formData = useSelector((state) => state.asr.selectedAsrSettings)
    const dispatch = useDispatch()

    const isLaptopOrMobile = useMediaQuery({ query: '(max-width: 768px)' })

    const handleChangeCheckbox = (event: any) => {
      dispatch(asrUpdateSettings({ ...formData, [event.target.name]: +event.nativeEvent.target.checked }))
      setLocalStorageInfo('asr', formData)
    }

    return (
      <div
        className={
          !isLaptopOrMobile
            ? cn(classes.asrAdditionalBtnsWrapper, openModal && classes.asrAdditionalActive)
            : cn(classes.asrAdditionalBtnsContainer, !openModal && classes.closed)
        }
      >
        {!isLaptopOrMobile && (
          <div className={classes.asrAdditionalBtnsHeader}>
            <span className={classes.asrAdditionalBtnsTitle}>Продвинутые настройки</span>
            <button onClick={() => setOpenModal(false)} type="button" className={classes.asrAdditionalClose}>
              <CustomIcon icon="cross" />
            </button>
          </div>
        )}
        <div className={classes.asrAdditionalBtns}>
          {additionalAsrConfig.map((item) => (
            <div key={item.name + item.title}>
              <SwitchCheckbox
                title={item.title}
                name={item.name}
                checked={!!(formData as any)?.[item.name]}
                onChange={handleChangeCheckbox}
              />
            </div>
          )
          )}
        </div>
      </div>
    )
  }
)

const AsrMainSettings = memo(() => {
  const dispatch = useDispatch()
  const config: any = useSelector((state) => state.asr.config)
  const selectedAsrSettings = useSelector((state) => state.asr.selectedAsrSettings)
  const isStreaming = useSelector((state) => state.asr.stream.isStreaming)
  const isLoading = config?.status === 'loading'
  const isError = config?.error

  useEffect(() => {
    if (Object.keys(config?.data)?.length) return
    dispatch(getAsrConfig())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch])

  const asrSettingsConfig = useMemo(() => {
    const languageItems =
      Object.keys(config?.data?.acoustic_models || {})?.map((item: string) => ({
        name: languageTranslate[item] ?? item,
        value: item,
      })) || []

    const sampleRateItems =
      config?.data?.acoustic_models?.[selectedAsrSettings?.language]?.map((item: string) => ({
        name: item,
        value: item.toString(),
      })) || []

    return [
      {
        title: 'Распознавание',
        type: 'use_stream',
        component:
          typeof selectedAsrSettings?.use_stream !== 'undefined' ? (
            <OptionButtons
              disabled={isStreaming}
              classes={{
                buttonWrapper: cn(classes.asrOptionButtonsWrapper, isStreaming && classes.inactive + ' inactive'),
                buttonItem: classes.asrOptionButton,
              }}
              buttons={[
                { name: 'Пакетное', value: 0 },
                { name: 'Потоковое', value: 1 },
              ]}
              initialButtonValue={
                selectedAsrSettings?.use_stream
                  ? { name: 'Потоковое', value: 1 }
                  : { name: 'Пакетное', value: 0 }
              }
              handleClick={(opt) => {
                dispatch(asrUpdateSettings({ ...selectedAsrSettings, use_stream: opt.value as number }))
              }}
            />
          ) : null,
        tooltipText: selectedAsrSettings?.use_stream
          ? 'Позволяет одновременно отправлять аудио поток на распознавание и получать результаты распознавания в рамках одного соединения.'
          : 'Подходит для распознавания аудиозаписей в виде файлов',
      },
      {
        title: 'Язык',
        type: 'language',
        component: selectedAsrSettings?.language ? (
          <CustomSelect
            classes={{
              selectorWrapperClassName: cn(classes.asrSettingSelect, isStreaming && classes.inactive),
              outClickClassName: classes.asrSettingSelectOutClick,
            }}
            mobileTitle="Выбор языка"
            items={languageItems}
            initialValue={{
              name: languageTranslate[selectedAsrSettings.language] ?? selectedAsrSettings.language,
              value: selectedAsrSettings.language,
            }}
            handleChange={(option) =>
              dispatch(
                asrUpdateSettings({
                  ...selectedAsrSettings,
                  language: option.value as string,
                  decoder_name: config?.data?.decoders?.[option.value][0],
                  sample_rate: config?.data?.acoustic_models?.[option.value][0],
                })
              )
            }
          />
        ) : null,
        tooltipText: 'Поддерживаемые языки распознавания.',
      },
      {
        title: 'Словарь',
        type: 'decoder',
        component: selectedAsrSettings.language ? (
          <CustomSelect
            mobileTitle="Словарь"
            items={config?.data?.decoders?.[selectedAsrSettings.language].map((item: string) => ({
              name: item,
              value: item,
            }))}
            classes={{
              selectorWrapperClassName: cn(classes.asrSettingSelect, isStreaming && classes.inactive),
              outClickClassName: classes.asrSettingSelectOutClick,
            }}
            initialValue={{ name: selectedAsrSettings.decoder_name, value: selectedAsrSettings.decoder_name }}
            handleChange={(option) =>
              dispatch(asrUpdateSettings({ ...selectedAsrSettings, decoder_name: option.value as string }))
            }
          />
        ) : null,
        tooltipText:
          'Языковая модель, которая обучена на большом массиве текстовых данных. Основная поддерживаемая модель — general. Она распознает речь на любую тему на заданном языке: короткие и длинные фразы, а также имена, адреса, даты и числа.',
      },
      {
        title: 'Частота',
        type: 'sample_rate',
        component: selectedAsrSettings.language ? (
          <OptionButtons
            disabled={isStreaming}
            buttons={sampleRateItems}
            initialButtonValue={{
              name: selectedAsrSettings.sample_rate,
              value: selectedAsrSettings.sample_rate.toString(),
            }}
            classes={{
              buttonWrapper: cn(classes.asrOptionButtonsWrapper, isStreaming && classes.inactive + ' inactive'),
              buttonItem: cn(classes.asrOptionButton, sampleRateItems.length === 1 && classes.aloneButton),
            }}
            handleClick={(opt) =>
              dispatch(asrUpdateSettings({ ...selectedAsrSettings, sample_rate: opt.value as string }))
            }
          />
        ) : null,
        tooltipText:
          'Акустические модели с частотой дискретизации 16 кГц подходят для широкополосного аудио, записанного на микрофон пользовательского устройства; 8 кГц – модель, предназначенная для телефонии.',
      },
    ]
  }, [config, dispatch, isStreaming, selectedAsrSettings])

  return (
    <div className={classes.asrSettingsList}>
      {asrSettingsConfig.map((item, index) => (
        <div key={item.title + index} className={classes.asrSettingItem}>
          <div className={cn(classes.asrSettingItemTitle, isStreaming && classes.inactive)}>
            <PortalTooltip
              stayTooltipOnHover
              showOnMobile
              classes={{ childrenWrapperClassName: classes.infoIcon }}
              tooltipContent={item.tooltipText}
              placement="right-middle"
            >
              <CustomIcon icon="info" />
            </PortalTooltip>
            <span>{item.title}</span>
          </div>
          {isLoading && !isError ? (
            <div className={classes.loaderWrapper}>
              <Loader />
            </div>
          ) : (
            item.component
          )}
          {isError && 'Error'}
        </div>
      ))}
    </div>
  )
})

export const AsrSettings = memo(() => {
  const [openModal, setOpenModal] = useState(false)

  return (
    <div className={classes.asrSettingsWrapper}>
      <AsrMainSettings />
      <div className={classes.asrModalBtnWrapper}>
        <button
          className={cn(classes.asrModalBtn, openModal && classes.active)}
          type="button"
          onClick={() => setOpenModal(!openModal)}
        >
          <CustomIcon icon="settings" />
          <span>Продвинутые настройки</span>
        </button>
      </div>
      <AsrAdditionalSettings openModal={openModal} setOpenModal={setOpenModal} />
    </div>
  )
})

AsrSettings.displayName = 'AsrSettings'
