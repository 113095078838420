import { FC } from 'react'
import classes from '../../styles/checkbox.module.scss'
import cn from 'classnames'

export const Checkbox: FC<{ 
  isChecked: boolean;
  variant?: 'default' | 'inverted'
  handleCheckboxChange: (e?: any) => void 
}> = ({
  isChecked,
  handleCheckboxChange,
  variant = 'default'
}) => {
  return (
    <label>
      <input 
        className={cn(classes.checkBoxInput, variant === 'inverted' && classes.checkBoxInputInverted)} 
        type="checkbox" checked={isChecked} 
        onChange={handleCheckboxChange} 
      />
    </label>
  )
}
