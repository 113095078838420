import { FC } from 'react'
import cn from 'classnames'
import classes from '../styles/skeleton.module.scss'


export const Skeleton: FC<{ type: 'avatar' | 'card' }> = ({ type }) => {

    return <div className={cn(
        classes.skeleton, 
        type === 'avatar' && classes.skeletonAvatar,
        type === 'card' && classes.skeletonCard
    )}> </div>
}
