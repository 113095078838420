import cn from 'classnames'
import classes from '../../styles/checkbox.module.scss'
import React, { FC } from 'react'

export const SwitchCheckbox: FC<{ 
  checked: boolean; 
  title?: string; 
  name: string;
  classes?: {
    checkboxItem: string
  }
  onChange: (e: React.ChangeEvent) => void 
}> = ({
  checked,
  onChange,
  name,
  title,
  classes: customClasses
}) => {
  return (
    <label className={cn(classes.checkBoxItem, customClasses)}>
      {title && <span className={cn(classes.checkBoxText, checked && classes.active)}>{title}</span>}
      <input name={name} checked={checked} type="checkbox" onChange={onChange} />
      <span className={classes.checkBoxAsr} />
    </label>
  )
}
