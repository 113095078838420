import classes from '../../../styles/tts/ttsFilters.module.scss'
import { memo } from 'react'
import { useMediaQuery } from 'react-responsive'
import { ttsUpdateListFilters } from '../../../store/slices/tts/ttsSlice'
import { CustomButton } from '../../../components/customButton'
import { useSelector, useDispatch } from '../../../hooks/redux'
import cn from 'classnames'
import { SwitchCheckbox } from '../../../components/checkbox/switchCheckbox'
import { initialFilterLanguages } from '../../../store/slices/tts/ttsSlice'
import { languageTranslate } from '../../asr/settings/config'

export const TtsListFilters = memo(() => {
  const dispatch = useDispatch()
  const sex = useSelector((state) => state.tts.listFilters.sex)
  const selectedLanguages = useSelector((state) => state.tts.listFilters.language)
  const isLaptopOrMobile = useMediaQuery({ query: '(max-width: 970px)' })
  const isMobile = useMediaQuery({ query: '(max-width: 450px)' })

  const handleSetFilter = (val: string) => {
    if (selectedLanguages.includes(val)) {
      const filtered = selectedLanguages.filter((lng) => lng !== val)
      return dispatch(ttsUpdateListFilters({ language: filtered.length === 0 ? initialFilterLanguages : filtered }))
    }
  
    const updatedLanguages = [...selectedLanguages, val].sort((a, b) => initialFilterLanguages.indexOf(a) - initialFilterLanguages.indexOf(b))
    dispatch(ttsUpdateListFilters({ language: updatedLanguages }))
  }

  return (
    <div className={classes.ttsListFiltersWrapper}>
      <div className={classes.ttsSexListFilter}>
        <span className={classes.ttsFilterListTitle}>Пол</span>
        <div className={classes.ttsSexListFilterBtns}>
          <CustomButton
            viewType="outline"
            className={cn(sex === 'male' && classes.active)}
            onClick={() => dispatch(ttsUpdateListFilters({ sex: sex === 'male' ? '' : 'male' }))}
          >
            {isLaptopOrMobile ? 'Мужской' : ' М'}
          </CustomButton>
          <CustomButton
            viewType="outline"
            className={cn(sex === 'female' && classes.active)}
            onClick={() => dispatch(ttsUpdateListFilters({ sex: sex === 'female' ? '' : 'female' }))}
          >
            {isLaptopOrMobile ? 'Женский' : 'Ж'}
          </CustomButton>
        </div>
      </div>
      <div className={classes.ttsSexListFilter}>
        <span className={classes.ttsFilterListTitle}>Языки</span>
        <div className={!isLaptopOrMobile ? classes.ttsLanguageListFilterBtns : classes.ttsSexListFilterBtns}>
          {initialFilterLanguages.map((lng) => {
            return (
              <CustomButton
                key={lng}
                viewType="outline"
                className={cn(selectedLanguages.includes(lng) && classes.active)}
                onClick={() => handleSetFilter(lng)}
              >
                {!isMobile ? languageTranslate[lng] : languageTranslate[lng].slice(0, 3)}
              </CustomButton>
            )
          })}
        </div>
      </div>
    </div>
  )
})

export const TtsListSsmlMarkupFilter = () => {
  const dispatch = useDispatch()
  const sanitizeState = useSelector((state) => state.tts.listFilters.sanitize)
  const isLaptopOrMobile = useMediaQuery({ query: '(max-width: 970px)' })

  return (
    <>
      {!isLaptopOrMobile ? (
        <div className={classes.ttsSSMLListFilter}>
          <span className={classes.ttsFilterListTitle}>Настройки текста</span>
          <div className={classes.ttsSSMLListFilterBtns}>
            <CustomButton
              viewType="outline"
              className={cn(sanitizeState === 'ssml' && classes.active)}
              onClick={() => dispatch(ttsUpdateListFilters({ sanitize: sanitizeState !== 'ssml' ? 'ssml' : 'none' }))}
            >
              SSML
            </CustomButton>
            <CustomButton
              viewType="outline"
              className={cn(sanitizeState === 'normalization' && classes.active)}
              onClick={() =>
                dispatch(
                  ttsUpdateListFilters({ sanitize: sanitizeState !== 'normalization' ? 'normalization' : 'none' })
                )
              }
            >
              Нормализация
            </CustomButton>
          </div>
        </div>
      ) : (
        <div className={classes.ttsSSMLListFilterCkeckboxes}>
            {/* <span className={classes.checked}>Нормализация</span> */}
            <SwitchCheckbox
              classes={{checkboxItem: classes.ttsSSMLCheckBoxItem}}
              title='Нормализация'
              checked={sanitizeState === 'normalization'}
              name="normalization"
              onChange={() => {
                dispatch(
                  ttsUpdateListFilters({ sanitize: sanitizeState !== 'normalization' ? 'normalization' : 'none' })
                )
              }}
            />
            <SwitchCheckbox
              classes={{checkboxItem: classes.ttsSSMLCheckBoxItem}}
              title='SSML'
              checked={sanitizeState === 'ssml'}
              name="ssml"
              onChange={() => {
                dispatch(ttsUpdateListFilters({ sanitize: sanitizeState !== 'ssml' ? 'ssml' : 'none' }))
              }}
            />
        </div>
      )}
    </>
  )
}
