import { Navigation } from './navigation'
import { Link, useLocation } from 'react-router-dom'
import { CustomIcon } from '../components/customIcon/customIcon'

import classes from '../styles/header.module.scss'
import { Container } from '../components/container'
import { useMediaQuery } from 'react-responsive'
import { useSelector } from '../hooks/redux'
import { AsrFilters } from './asr/filter/filter'
import { TtsFilters } from './tts/ttsFilters/ttsFilters'
import { AdminHeader } from './admin/adminHeader'

const AsrHeaderFilter = () => {
  const { pathname } = useLocation()
  const isLaptopOrMobile = useMediaQuery({ query: '(max-width: 1070px)' })
  const asrHasMessages = useSelector((state) => state.asr.messages.length)
  const isAsr = ['/'].includes(pathname)

  const showFilter = asrHasMessages && isAsr && isLaptopOrMobile

  return showFilter ? <AsrFilters /> : null
}

const TtsHeaderFilter = () => {
  const isLaptopOrMobile = useMediaQuery({ query: '(max-width: 970px)' })
  const ttsHasMessages = useSelector((state) => state.tts.messages.length)
  const { pathname } = useLocation()

  const isTts = ['/tts/', '/tts'].includes(pathname)
  const showFilter = ttsHasMessages && isTts && isLaptopOrMobile

  return showFilter ? <TtsFilters /> : null
}

const AdminHeaderFilter = () => {
  const { pathname } = useLocation()
  const isLaptopOrMobile = useMediaQuery({ query: '(max-width: 1070px)' })
  const isAdmin = ['/admin'].includes(pathname)

  const showFilter = isAdmin && isLaptopOrMobile

  return showFilter ? <AdminHeader /> : null
}

export const Header = () => {
  const isSmallScreen = useMediaQuery({ query: '(max-width: 576px)' })

  return (
    <header className={classes.header}>
      <Container>
        <div className={classes.headerWrapper}>
          <div className={classes.headerLogo}>
            <Link to="/">
              <CustomIcon icon="logo" />
              {!isSmallScreen && 'Наносемантика'}
            </Link>
          </div>
          <Navigation />
        </div>
        <TtsHeaderFilter />
        <AsrHeaderFilter />
        <AdminHeaderFilter />
      </Container>
    </header>
  )
}
