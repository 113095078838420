import { useSelector, useDispatch } from '../../../hooks/redux'
import { useCallback, useMemo } from 'react'
import { IVoice } from '../../../@types/tts'
import { ttsSetAudio, ttsSetRemovePickedVoice, ttsToggleShowEmotions } from '../../../store/slices/tts/ttsSlice'
import classes from '../../../styles/tts/ttsModal.module.scss'
import cn from 'classnames'
import { CustomIcon } from '../../../components/customIcon/customIcon'
import { PlayerV2, THandlePlayerChangeProps } from '../../../components/player/playerV2'
import { PortalTooltip } from '../../../components/tooltip/portalTooltip'
import { EmotionList } from '../emotionList'
import { LanguageIcons } from '../../../components/languageIcons'
import { Skeleton } from '../../../components/skeleton'
import { Checkbox } from '../../../components/checkbox/checkbox'
import defaultAvatar from '../../../assets/defaultUser.png'
import NatashaPreview from '../../../assets/natasha-preview.wav'
import ArtemPreview from '../../../assets/artem-preview.wav'


export const ModalVoices = () => {
  const dispatch = useDispatch()
  const voices = useSelector((state) => state.tts.voices)
  const ttsFilters = useSelector((state) => state.tts.filters)
  const showEmotions = useSelector((state) => state.tts.showEmotions)
  const currentAudioId = useSelector((state) => state.tts.currentAudio.id)

  const handleActiveVoice = (voice: IVoice) => {
    dispatch(ttsSetRemovePickedVoice({ name: voice.name }))
  }

  const handleTouchMove = (e: React.TouchEvent<HTMLDivElement>) => {
    e.stopPropagation()
  }

  const handleShowEmotions = () => {
    dispatch(ttsToggleShowEmotions())
  }

  const handlePlay = (waveSurfer: WaveSurfer, id: string) => {
    const totalTime = waveSurfer.current?.getDuration()
    const currentTime = waveSurfer.current?.getCurrentTime()

    dispatch(ttsSetAudio({ currentTime, totalTime, id }))
  }

  const filteredVoices = useMemo(() => {
    let arr = voices

    if (ttsFilters?.sex?.length) {
      arr = arr.filter((item) => ttsFilters.sex.includes(item.sex) || item?.sex === '')
    }

    if (ttsFilters?.language?.length) {
      arr = arr.filter((item) => item.languages.length === 0 || ttsFilters.language.includes(item.languages[0]))
    }

    return arr.map(voice => {
      if (voice.name === 'natasha') {
        return {
          ...voice,
          previewAudio: NatashaPreview
        }
      } else if (voice.name === 'artem') {
        return {
          ...voice,
          previewAudio: ArtemPreview
        }
      }
      return voice
    })
  }, [voices, ttsFilters])

  return (
    <div className={classes.ttsModalVoicesWrapper} onTouchMove={handleTouchMove}>
      {filteredVoices.map((voice, index) => {
        const avatar = voice.avatar || defaultAvatar
        const id = voice.name + index

        return (
          <div
            key={id}
            onClick={() => handleActiveVoice(voice)}
            className={cn(
              classes.ttsModalVoiceItem,
              voice.picked && classes.ttsPickedVoice,
              voice.sex === 'female' && classes.ttsFemaleVoice
            )}
          >
            <div className={classes.ttsModalCheckbox}>
              <Checkbox variant='inverted' isChecked={voice.picked} handleCheckboxChange={() => { }} />
            </div>
            {voice.tooltip && (
              <PortalTooltip
                stayTooltipOnHover={true}
                classes={{
                  childrenWrapperClassName: classes.ttsModalTooltip,
                  contentClassName: classes.ttsModalTooltipContent
                }}
                placement="top-middle"
                showOnMobile
                tooltipContent={voice.tooltip}
              >
                <CustomIcon icon="info" />
              </PortalTooltip>
            )}
            <div className={classes.ttsModalVoiceAvatar}>
              {avatar ? (
                <img src={avatar} alt={voice.avatar} />
              )
                : <Skeleton type='card' />
              }
            </div>
            {
              voice.previewAudio && (
                <PlayerV2
                  classes={{player: classes.playerModal}}
                  stopPlayer={currentAudioId !== id}
                  handlePlay={(waveSurfer) => handlePlay(waveSurfer, id)}
                  url={voice.previewAudio}
                  toggleOnly
                />)
            }

            <div className={classes.ttsModalVoiceContent}>
              <div className={classes.ttsModalVoiceName}>
                <span>{voice.title}</span>
                {/* {voice.emotion && <img className={classes.ttsVoiceEmotionMark} src={star} alt="emotion" />} */}
              </div>
              {voice.sex !== '' && (
                <div className={classes.ttsModalInfoIcons}>
                  <div className={classes.ttsModalOtherContent}>
                    <CustomIcon icon={voice.sex} />
                  </div>
                  <LanguageIcons languages={voice.languages} />
                </div>
              )}
              {voice.emotion && (
                <EmotionList
                  showEmotions={showEmotions}
                  handleShowEmotions={handleShowEmotions}
                  selectedEmotion={voice.emotion}
                  voice={voice.name}
                  emotions={voice.availableEmotions}
                />
              )}
            </div>
          </div>
        )
      })}
    </div>
  )
}
