import { useState, FC, useCallback } from 'react'
import { consoleSetAudio, setSelectedSessions } from '../../store/slices/statisticsConsole/consoleSlice'
import { CustomIcon } from '../../components/customIcon/customIcon'
import { PortalTooltip } from '../../components/tooltip/portalTooltip'
import classes from '../../styles/statisticsConsole/console.module.scss'
import cn from 'classnames'
import { Checkbox } from '../../components/checkbox/checkbox'
import { IConsoleSessions } from '../../@types/console'
import { PlayerV2, THandlePlayerChangeProps } from '../../components/player/playerV2'
import { ConsoleDrawer } from './consoleDrawer'
import { useSelector, useDispatch } from '../../hooks/redux'
import { urlConsole, urlAsr, urlSynthesis } from '../../config'
import { formatDateString } from '../../functions/date/formatDate'


export const SessionsListItem: FC<{ item: IConsoleSessions }> = ({ item }) => {
    const { records, username, timestamp, sample_rate } = item
    const reversedRecords = [...records].reverse()
    const [isOpened, setIsOpened] = useState<boolean>(false)
    const [drawerRecord, setDrawerRecord] = useState<number | null>(null)
    const [hoveredSession, setHoveredSession] = useState<number | null>(null)
    const selectedSessionIds = useSelector((state) => state.console.selectedSessionIds)
    const serviceType = useSelector((state) => state.console.stats?.service_type)
    const token = useSelector(state => state.user.user.auth_token)
    const dispatch = useDispatch()
    const id = item.id
    const isSessionSelected = selectedSessionIds.includes(id)
    const sessionUrl = serviceType === 'asr'
        ? urlAsr + '/records?token=' + token + '&session_id=' + id
        : urlSynthesis + '/media?token=' + token + '&session_id=' + id

    const toggleDrawer = (id: number | null) => {
        setDrawerRecord(id)
    }

    const handleAudioChange = useCallback(
        ({ currentTime }: THandlePlayerChangeProps) => {
            dispatch(consoleSetAudio({ currentTime, id }))
        },
        [dispatch, id]
    )

    const handlePlay = (waveSurfer: WaveSurfer) => {
        const totalTime = waveSurfer.current?.getDuration()
        const currentTime = waveSurfer.current?.getCurrentTime()

        dispatch(consoleSetAudio({ currentTime, totalTime, id }))
    }

    const showHideMainText = () => {
        setIsOpened(prevState => !prevState)
    }

    const handleMouseEnter = (sessionId: number) => {
        setHoveredSession(sessionId)
    }

    const handleMouseLeave = () => {
        setHoveredSession(null)
    }

    const handleSelectSession = () => {
        dispatch(setSelectedSessions(id))
    }

    const mainText = (serviceType === 'asr'
        ? reversedRecords.map(record => record.text).join(' ')
        : item.text
    ) || ''

    const avatar = serviceType === 'asr'
        ? username[0].toUpperCase()
        : (item.voice || username)[0].toUpperCase()

    return (
        <div key={id} className={classes.sessionsListItem}>
            <div className={cn(classes.sessionsListItemContainer, isOpened && classes.mainContainer)}>
                <div
                    onMouseEnter={() => handleMouseEnter(id)}
                    onMouseLeave={handleMouseLeave}
                    className={classes.cardInfo}
                >
                    <div className={cn(classes.avatar, (id === hoveredSession || isSessionSelected) && classes.selected)}>
                        {(id !== hoveredSession && !isSessionSelected)
                            ? avatar
                            : <Checkbox isChecked={isSessionSelected} handleCheckboxChange={handleSelectSession} />
                        }
                    </div>
                    <div className={classes.textPreview}>
                        <span className={classes.textPreviewUsername}>{serviceType === 'asr' ? username : item.voice}</span>
                        <span className={classes.textPreviewDate}>{formatDateString(timestamp)}</span>
                    </div>
                </div>
                <span className={classes.mainText}>{isOpened ? mainText : (mainText.slice(0, 150))}</span>
                <button onClick={showHideMainText} className={classes.hideMainTextButton}>
                    <CustomIcon icon={isOpened ? 'arrowUp' : 'arrowDown'} />
                </button>
                <div className={cn(classes.sessionsAudio, serviceType === 'tts' && classes.sessionsAudioTts)}>
                    <PlayerV2
                        waveColor='#014EBC33'
                        primaryColor='#014EBC'
                        handleOnSeek={handleAudioChange}
                        handleChange={handleAudioChange}
                        handlePlay={handlePlay}
                        barWidth={4}
                        url={sessionUrl}
                        classes={{
                            volumeBtn: classes.consoleVolumeBtn,
                            playPauseBtn: classes.consolePlayPauseBtn,
                            playerTime: classes.consolePlayerTime
                        }}
                    />
                    {
                        serviceType === 'asr'
                            ? <PortalTooltip
                                placement='bottom-middle'
                                triangle={false}
                                classes={{
                                    childrenWrapperClassName: classes.consoleSessionTooltip,
                                    contentClassName: classes.consoleSessionContent
                                }}
                                tooltipContent={'Канал ' + item.records[0].channel}>
                                <CustomIcon icon='info' />
                            </PortalTooltip>
                            : null
                    }
                </div>
            </div>
            {isOpened && (
                <div className={classes.sessionRecordsContainer}>
                    {
                        reversedRecords.map(record => {
                            const confidence = record?.confidence || 0
                            const confidenceClass = confidence < 60
                                ? classes.red
                                : confidence >= 60 && confidence <= 79
                                    ? classes.blue
                                    : classes.green

                            const url = records.length === 1 ? sessionUrl : urlConsole + `/${serviceType}/records/` + record.id + '/audio'
                            // const res = fetchAudio({ token, audioLink: urlConsole + '/asr/records/' + record.id + '/audio' })

                            return (
                                <div key={record.id} className={classes.sessionRecords}>
                                    <div className={classes.recordContent}>
                                        <div>
                                            <PlayerV2
                                                url={url}
                                                classes={{
                                                    player: classes.recordCustomPlayer,
                                                    playPauseBtn: classes.consolePlayPauseBtn
                                                }}
                                            />
                                        </div>
                                        <span>{serviceType === 'asr' ? record.text : record.normalized_text}</span>
                                    </div>
                                    <div className={classes.recordInfo}>
                                        <button onClick={() => toggleDrawer(record.id)} className={classes.additionalInfo}><CustomIcon icon='info' /></button>
                                        {serviceType === 'asr'
                                            ?
                                            <span className={cn(classes.recordConfidence, confidenceClass)}>
                                                <CustomIcon icon="confidence" />
                                                {Math.round(confidence)}
                                            </span>
                                            : null}
                                    </div>
                                    <ConsoleDrawer
                                        isOpen={drawerRecord === record.id}
                                        onClose={() => toggleDrawer(null)}
                                        config={record}
                                        sessionId={id}
                                        username={username}
                                        confidenceClass={confidenceClass}
                                        audioUrl={url}
                                        serviceType={serviceType}
                                        voice={item?.voice}
                                        sampleRate={sample_rate}
                                    />
                                </div>
                            )
                        })
                    }
                </div>
            )}
        </div>
    )
}