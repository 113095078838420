import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { getTtsConfig, ttsSend } from './async'
import { IConfigVoice, IStateTts, ITtsMessages, IVoice, TSex } from '../../../@types/tts'
import { initialTtsSettings } from './config'
import { getLocalStorageInfo, setLocalStorageInfo } from '../../../tools'

export const initialFilterLanguages = ['ru', 'en', 'kk']
export const initialSexFilter: TSex[] = ['male', 'female']
const initialVoicesWithPicked = getLocalStorageInfo('tts')?.voices

const initialState: IStateTts = {
  voices: initialVoicesWithPicked || [],
  messages: getLocalStorageInfo('tts')?.messages || [],
  settings: getLocalStorageInfo('tts')?.settings || initialTtsSettings,
  showEmotions: false,
  filters: {
    language: initialFilterLanguages,
    sex: initialSexFilter,
  },
  listFilters: {
    language: initialFilterLanguages,
    sex: '',
    sanitize: 'none',
    searchString: '',
  },
  loading: false,
  currentAudio: {
    id: '',
    currentTime: 0,
    totalTime: 0,
  },
}

const ttsSlice = createSlice({
  name: 'tts',
  initialState,
  reducers: {
    ttsUpdateSettings: (state, actions) => {
      const newSettings = { ...state.settings, ...actions.payload }
      setLocalStorageInfo('tts', { ...getLocalStorageInfo('tts'), settings: newSettings })
      return { ...state, settings: newSettings }
    },
    ttsUpdateModalFilters: (state, action) => {
      return { ...state, filters: { ...state.filters, ...action.payload } }
    },
    ttsUpdateListFilters: (state, action) => {
      return { ...state, listFilters: { ...state.listFilters, ...action.payload } }
    },
    ttsShowHideMessage: (state, action: PayloadAction<undefined | { id?: string; value?: boolean }>) => {
      const id = action?.payload?.id
      if (id) {
        const messages = state.messages.map((item) => (item.id === id ? { ...item, isOpened: !item.isOpened } : item))
        setLocalStorageInfo('tts', { ...getLocalStorageInfo('tts'), messages })
        return { ...state, messages }
      }

      const messages = state.messages.map((item) => ({ ...item, isOpened: action.payload?.value ?? false }))
      setLocalStorageInfo('tts', { ...getLocalStorageInfo('tts'), messages })

      return {
        ...state,
        messages,
      }
    },
    ttsSetAudio: (state, action) => {
      if (action.payload?.id && action.payload?.id !== state.currentAudio.id) state.currentAudio.id = action.payload.id
      if (action.payload.currentTime) state.currentAudio.currentTime = action.payload.currentTime
    },
    ttsSetRemovePickedVoice: (state, action) => {
      const voices = state.voices.map((item) =>
        action.payload.name === item.name ? { ...item, picked: !item.picked } : item
      )
      setLocalStorageInfo('tts', { ...getLocalStorageInfo('tts'), voices })
      return {
        ...state,
        voices,
      }
    },
    ttsSetEmotionVoice: (state, action) => {
      const voices = state.voices.map((item) =>
        action.payload.name === item.name ? { ...item, emotion: action.payload.emotion } : item
      )
      setLocalStorageInfo('tts', { ...getLocalStorageInfo('tts'), voices })
      return {
        ...state,
        voices,
      }
    },
    ttsUpdatePickedVoices: (state, action) => {
      const newVoices = action.payload
      setLocalStorageInfo('tts', { ...state, voices: newVoices })

      return {
        ...state,
        voices: newVoices,
      }
    },
    ttsClearMessages: (state) => {
      state.messages = []
      setLocalStorageInfo('tts', { ...getLocalStorageInfo('tts'), messages: [] })
    },
    ttsRemoveMessage: (state, action) => {
      const messages = state.messages.filter((item) => item.id !== action.payload.id)

      try {
        setLocalStorageInfo('tts', { ...getLocalStorageInfo('tts'), messages })
        return { ...state, messages }
      } catch (e) {
        return { ...state, messages }
      }
    },
    ttsSetUpdateCache: (state, action) => {
      return {
        ...state,
        settings: {
          ...state.settings,
          update_cache: action.payload
        }
      }
    },
    ttsToggleShowEmotions: (state) => {
      return {
        ...state,
        showEmotions: !state.showEmotions
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(ttsSend.pending, (state) => {
        state.loading = true
      })
      .addCase(ttsSend.fulfilled, (state, action: PayloadAction<ITtsMessages>) => {
        if (!action.payload?.response_audio_url || !Object.keys(action.payload).length) {
          state.loading = false
          return
        }

        const sanitizeText = action.payload.text.original.replace(/<[^>]*>/gi, '')
        const currentVoice = getLocalStorageInfo('tts')?.voices.find(
          (item: IVoice) => item.name === action.payload.voice
        )

        const newMessage = {
          ...action.payload,
          response_audio: '',
          languages: currentVoice?.languages || [],
          sex: currentVoice?.sex || '',
          emotion: currentVoice?.emotion || '',
          avatarUrl: currentVoice?.avatar,
          sanitizeText,
        }

        const groupId = action.payload.id
        const messages = state.messages.map((message) =>
          message.id === groupId ? { ...message, voices: [...message.voices, newMessage] } : message
        )

        const foundMessagesGroupIndex = messages.findIndex((message) => message.id === groupId)

        if (foundMessagesGroupIndex === -1) {
          const newMessagesGroup = {
            id: groupId,
            isOpened: true,
            text: action.payload.text.original,
            pitch: action.payload.pitch,
            rate: action.payload.rate,
            volume: action.payload.volume,
            voices: [newMessage],
          }

          messages.unshift(newMessagesGroup)
        }

        try {
          setLocalStorageInfo('tts', { ...getLocalStorageInfo('tts'), messages })
          state.messages = messages
          state.loading = false
        } catch (e) {
          state.loading = false
        }
      })
      .addCase(ttsSend.rejected, (state) => {
        state.loading = false
      })
      .addCase(getTtsConfig.fulfilled, (state, action: PayloadAction<IConfigVoice[]>) => {
        const existingVoices = state.voices || []
        const newVoices: IVoice[] = action.payload
          .filter(voice => voice.voice !== 'all')
          .map(({ voice: name, emotions: availableEmotions }) => ({
            name,
            sex: '',
            picked: true,
            title: name,
            languages: [],
            avatar: '',
            previewAudio: '',
            availableEmotions: availableEmotions || [],
            emotion: (availableEmotions || [])[0]
          }))

        const updatedVoices = newVoices.map(newVoice => {
          const existingVoice = existingVoices.find(v => v.name === newVoice.name)

          if (existingVoice) {
            const isEmotionChanged = JSON.stringify(existingVoice.availableEmotions) !== JSON.stringify(newVoice.availableEmotions)
            return {
              ...existingVoice,
              availableEmotions: isEmotionChanged ? newVoice.availableEmotions : existingVoice.availableEmotions,
              emotion: isEmotionChanged ? newVoice.emotion : existingVoice.emotion,
            }
          } else {
            return newVoice
          }
        })

        setLocalStorageInfo('tts', { ...getLocalStorageInfo('tts'), voices: updatedVoices })

        return {
          ...state,
          voices: updatedVoices
        }
      })
  },
})

const { actions, reducer } = ttsSlice

export const {
  ttsClearMessages,
  ttsSetRemovePickedVoice,
  ttsUpdateSettings,
  ttsUpdateModalFilters,
  ttsUpdateListFilters,
  ttsRemoveMessage,
  ttsShowHideMessage,
  ttsSetAudio,
  ttsUpdatePickedVoices,
  ttsSetEmotionVoice,
  ttsSetUpdateCache,
  ttsToggleShowEmotions
} = actions

export default reducer
