import asrClasses from '../../../styles/filter.module.scss'
import classes from '../../../styles/tts/ttsFilters.module.scss'
import { CustomButton } from '../../../components/customButton'
import { CustomIcon } from '../../../components/customIcon/customIcon'
import { CustomButtonSelect } from '../../../components/customButtonSelect'
import { useMediaQuery } from 'react-responsive'
import { ttsClearMessages, ttsShowHideMessage, ttsUpdateListFilters } from '../../../store/slices/tts/ttsSlice'
import { memo, useRef, useState, useMemo, ChangeEvent } from 'react'
import { useSelector, useDispatch } from '../../../hooks/redux'
import { TtsListFilters, TtsListSsmlMarkupFilter } from './ttsListFilters'
import cn from 'classnames'
import { PortalTooltip } from '../../../components/tooltip/portalTooltip'
import { useMobileFilter } from '../../../hooks/common'
import { MobilePopup } from '../../../components/mobilePopup'
import modalClasses from '../../../styles/mobilePopup.module.scss'
import { SearchField } from '../../../components/textField/searchField'


export const TtsFilters = memo((is) => {
  const dispatch = useDispatch()
  const isAnybodyOpened = useSelector((state) => state.tts.messages.some((item) => item.isOpened))
  const isMobile = useMediaQuery({ query: '(max-width: 400px)' })
  const { mobileFilterActive } = useMobileFilter(isMobile)
  const isLaptopOrMobile = useMediaQuery({ query: '(max-width: 970px)' })
  const listFilters = useSelector((state) => state.tts.listFilters)
  const ref = useRef<HTMLDivElement>(null)
  const [showModal, setShowModal] = useState(false)
  const [stringState, setStringState] = useState<'filter' | 'ssml'>('filter')

  const initialFilters = useRef(listFilters).current
  const filtersChanged = useMemo(() => {
    const { searchString: _, ...restOfListFilters } = listFilters
    const { searchString: __, ...restOfInitialFilters } = initialFilters
  
    return JSON.stringify(restOfListFilters) !== JSON.stringify(restOfInitialFilters)
  }, [listFilters, initialFilters])

  const handleClear = () => {
    dispatch(ttsClearMessages())
  }

  const handleClearInput = () => {
    dispatch(ttsUpdateListFilters({ searchString: '' }))
  }

  const handleChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
    dispatch(ttsUpdateListFilters({ searchString: e.target.value }))
  }

  return (
    <div
      ref={ref}
      className={cn(
        classes.ttsFiltersWrapper,
        isMobile && classes.ttsFilterMobile,
        mobileFilterActive && isMobile && classes.ttsFilterMobileActive
      )}
    >
      <SearchField
        searchString={listFilters.searchString}
        handleChangeInput={handleChangeInput}
        handleClearInput={handleClearInput}
      />
      <div className={classes.ttsFilterButtons}>
        <CustomButtonSelect
          hideSelect={isLaptopOrMobile}
          active={filtersChanged}
          classes={{
            content: classes.filterButtonContent,
            button: asrClasses.filterBtn
          }}
          contentComponent={<TtsListFilters />}
        >
          <PortalTooltip placement="top-middle" tooltipContent="Фильтры">
            <div
              {...(isLaptopOrMobile && {
                onClick: () => {
                  setStringState('filter')
                  setShowModal(true)
                },
              })}
              className={asrClasses.tooltipFilterClassName}
            >
              <CustomIcon icon={filtersChanged ? 'changedFilters' : 'filter'}/>
            </div>
          </PortalTooltip>
        </CustomButtonSelect>
        <CustomButtonSelect
          hideSelect={isLaptopOrMobile}
          classes={{
            content: classes.filterButtonContent,
            button: asrClasses.filterBtn
          }}
          contentComponent={<TtsListSsmlMarkupFilter />}
        >
          <PortalTooltip placement="top-middle" tooltipContent="Ssml разметка">
            <div
              {...(isLaptopOrMobile && {
                onClick: () => {
                  setStringState('ssml')
                  setShowModal(true)
                },
              })}
              className={asrClasses.tooltipFilterClassName}
            >
              <CustomIcon icon="edit" />
            </div>
          </PortalTooltip>
        </CustomButtonSelect>
        <CustomButton
          className={classes.ttsFilterCustomButton}
          onClick={() => dispatch(ttsShowHideMessage(isAnybodyOpened ? {} : { value: true }))}
        >
          {!isMobile ? (
            <>{isAnybodyOpened ? 'Свернуть все' : 'Развернуть все'}</>
          ) : (
            <CustomIcon icon={isAnybodyOpened ? 'arrowUp' : 'arrowDown'} />
          )}
        </CustomButton>
        <CustomButton className={classes.ttsFilterCustomButton} onClick={handleClear}>
          {isMobile ? <CustomIcon icon="clear" /> : 'Очистить'}
        </CustomButton>
      </div>
      {isLaptopOrMobile && (
        <MobilePopup show={showModal} handleClose={() => setShowModal(false)}>
          <div className={modalClasses.modalPopupTitleWrapper}>
            <div className={modalClasses.mobilePopupTitle}>
              {stringState === 'filter' ? 'Фильтр' : 'Настройки текста'}
            </div>
            <button onClick={() => setShowModal(false)} className={modalClasses.mobilePopupCloseBtn}>
              Применить
            </button>
          </div>
          {stringState === 'filter' ? <TtsListFilters /> : <TtsListSsmlMarkupFilter />}
        </MobilePopup>
      )}
    </div>
  )
})
