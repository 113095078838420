import { useState, FC } from 'react'
import { CalendarProps } from '../../@types/calendar'
import { checkIsToday, createDate, checkDateIsEqual } from '../../functions/date'
import { useCalendar } from '../../hooks/useCalendar'
import { CustomIcon } from '../customIcon/customIcon'
import classes from '../../styles/dataRangePicker.module.scss'
import cn from 'classnames'

interface EnhancedCalendarProps extends CalendarProps {
  disablePrevButton?: boolean
  disableNextButton?: boolean
  rangeStart: Date | null
  rangeEnd: Date | null
  hoveredDay: Date | null
  handleDayMouseEnter: (date: Date) => void
  handleDayMouseLeave: () => void
  disabledMonth?: number
}

export const Calendar: FC<EnhancedCalendarProps> = ({
  locale = 'default',
  selectDate,
  firstWeekDayNumber = 2,
  onMonthChange,
  currentMonth,
  disablePrevButton,
  disableNextButton,
  rangeStart,
  rangeEnd,
  hoveredDay,
  handleDayMouseEnter,
  handleDayMouseLeave,
  disabledMonth
}) => {
  const { functions, state } = useCalendar({
    locale,
    selectedDate: createDate({ date: currentMonth }),
    firstWeekDayNumber
  })

  const { selectedYear, mode } = state

  const handlePrevMonth = () => {
    const prevMonth = new Date(currentMonth)
    prevMonth.setMonth(currentMonth.getMonth() - 1)
    onMonthChange(prevMonth)
    functions.onClickArrow('left')
  }

  const handleNextMonth = () => {
    const nextMonth = new Date(currentMonth)
    nextMonth.setMonth(currentMonth.getMonth() + 1)
    onMonthChange(nextMonth)
    functions.onClickArrow('right')
  }

  return (
    <div className={classes.calendar}>
      <div className={classes.calendarHeader}>
        <button
          aria-hidden
          disabled={disablePrevButton}
          className={classes.calendarHeaderArrowLeft}
          onClick={handlePrevMonth}
        >
          <CustomIcon icon='arrowRight' />
        </button>
        {mode === 'days' && (
          <div className={classes.calendarCurrentDate} aria-hidden onClick={() => functions.setMode('monthes')}>
            {state.monthesNames[state.selectedMonth.monthIndex].month} {selectedYear}
          </div>
        )}
        {mode === 'monthes' && (
          <div className={classes.calendarCurrentDate} aria-hidden onClick={() => functions.setMode('years')}>
            {selectedYear}
          </div>
        )}
        {mode === 'years' && (
          <div className={classes.calendarCurrentDate}>
            {state.selectedYearsInterval[0]} -{' '}
            {state.selectedYearsInterval[state.selectedYearsInterval.length - 1]}
          </div>
        )}
        <button
          aria-hidden
          disabled={disableNextButton}
          className={classes.calendarHeaderArrowRight}
          onClick={handleNextMonth}
        >
          <CustomIcon icon='arrowRight' />
        </button>
      </div>
      <div className={classes.calendarBody}>
        {mode === 'days' && (
          <>
            <div className={classes.calendarWeekNames}>
              {state.weekDaysNames.map((weekDaysName) => (
                <div key={weekDaysName.dayShort}>{weekDaysName.dayShort.toUpperCase()}</div>
              ))}
            </div>
            <div className={classes.calendarDays}>
              {state.calendarDays.slice(0, 42).map((day) => {
                const isAdditionalDay = day.monthIndex !== state.selectedMonth.monthIndex
                const isToday = checkIsToday(day.date) && !isAdditionalDay
                const isStartDay = checkDateIsEqual(day.date, rangeStart) && !isAdditionalDay
                const isEndDay = checkDateIsEqual(day.date, rangeEnd) && !isAdditionalDay
                const isSelectedDay = !isAdditionalDay && (isStartDay || isEndDay)
                const isDateInRange = !isAdditionalDay && rangeStart && (
                  (hoveredDay && !rangeEnd && (
                    (rangeStart < day.date && hoveredDay >= day.date) ||
                    (rangeStart > day.date && hoveredDay <= day.date)
                  )) ||
                  (rangeEnd && rangeStart < day.date && rangeEnd > day.date)
                )

                return (
                  <div
                    key={`${day.dayNumber}-${day.monthIndex}`}
                    onMouseEnter={() => !isAdditionalDay && handleDayMouseEnter(day.date)}
                    onMouseLeave={handleDayMouseLeave}
                    className={cn(
                      isStartDay && classes.calendarStartDay,
                      isEndDay && classes.calendarEndDay,
                      isDateInRange && classes.calendarDateInRange,
                    )}
                  >
                    <button
                      style={isToday ? {
                        color: '#FF6895'
                      } : {}}
                      aria-hidden
                      disabled={isAdditionalDay}
                      onClick={() => {
                        selectDate(day.date)
                      }}
                      className={cn(
                        classes.calendarDay,
                        isSelectedDay && classes.calendarSelectedItem,
                        isAdditionalDay && classes.calendarAdditionalDay,
                        isDateInRange && classes.calendarDateInRange
                      )}
                    >
                      {day.dayNumber}
                    </button>
                  </div>
                )
              })}
            </div>
          </>
        )}

        {mode === 'monthes' && (
          <div className={classes.calendarPickItemsContainer}>
            {state.monthesNames.map((monthesName) => {
              const isCurrentMonth =
                new Date().getMonth() === monthesName.monthIndex &&
                selectedYear === new Date().getFullYear()

              return (
                <div
                  key={monthesName.month}
                  aria-hidden
                  onClick={() => {
                    if (disabledMonth !== monthesName.monthIndex) {
                      functions.setSelectedMonthByIndex(monthesName.monthIndex)
                      functions.setMode('days')
                    }
                  }}
                  className={cn(
                    classes.calendarPickItem,
                    isCurrentMonth && classes.calendarTodayItem
                  )}
                >
                  {monthesName.monthShort}
                </div>
              )
            })}
          </div>
        )}

        {mode === 'years' && (
          <div className={classes.calendarPickItemsContainer}>
            <div
              className={cn(
                classes.calendarUnchoosableYear,
                classes.calendarPickItem
              )}>
              {state.selectedYearsInterval[0] - 1}
            </div>
            {state.selectedYearsInterval.map((year) => {
              const isCurrentYear = new Date().getFullYear() === year

              return (
                <div
                  key={year}
                  aria-hidden
                  onClick={() => {
                    functions.setSelectedYear(year)
                    functions.setMode('monthes')
                  }}
                  className={cn(
                    classes.calendarPickItem,
                    isCurrentYear && classes.calendarTodayItem,
                  )}
                >
                  {year}
                </div>
              )
            })}
            <div className={cn(
              classes.calendarUnchoosableYear,
              classes.calendarPickItem
            )}>
              {state.selectedYearsInterval[state.selectedYearsInterval.length - 1] + 1}
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
