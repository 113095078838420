import { createAsyncThunk } from '@reduxjs/toolkit'
import { Body, authPost, get } from '../../../api'
import { urlSynthesis } from '../../../config'
import { addNotify } from '../notify/notifySlice'

interface ISendPayload {
  pitch: number
  rate: number
  text: string
  voice: string
  volume: number
}
export const ttsSend = createAsyncThunk(
  'tts/ttsSend',
  async (data: ISendPayload & { id: string }, { dispatch }) => {
    const sentDate = Date.now()
    try {
      const res = await authPost(urlSynthesis + '/synthesize', { ...data } as Body)

      if (!res || res.status >= 400) {
        throw new Error('Ошибка на уровне HTTP-запроса')
      }

      if (res.data?.response_code === 2) {
        return res.data
      }

      if (res.data?.response_code === 3) {
        return dispatch(addNotify({ text: res.data?.msg }))
      }

      const generationTime = Date.now() - sentDate
      const message = res.data?.response?.[0] ?? {}

      return {
        ...message,
        generationTime,
        id: data.id,
        pitch: data.pitch,
        rate: data.rate,
        volume: data.volume,
      }
    } catch (error) {
      console.error('Ошибка в запросе:', error)

      if (error instanceof Error) {
        return dispatch(addNotify({ text: error.message }))
      }
      
      return dispatch(addNotify({ text: 'Неизвестная ошибка' }))
    }
  }
)


export const getTtsConfig = createAsyncThunk('tts/getConfig', async () => {
  const res = await get(urlSynthesis + '/get_config')

  if (res.data?.response_code === 2) return res.data

  return res.data?.response ?? []
})
