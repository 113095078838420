import { FilterHeader } from '../../layouts/statisticsConsole/filterHeader'
import { Container } from '../../components/container'
import { Sessions } from '../../layouts/statisticsConsole/sessions'
import { SearchFeedback } from '../../layouts/statisticsConsole/searchFeedback'
import { useSelector } from '../../hooks/redux'
import classes from '../../styles/statisticsConsole/console.module.scss'
import cn from 'classnames'


export const StatisticsConsole = () => {
  const sessionsLength = useSelector((state) => state.console.sessions.length)
  const loading = useSelector((state) => state.console.loading)

  return (
    <>
      <Container>
        <section className={classes.consoleSection}>
          <FilterHeader />
          <div className={cn(classes.sessionListWrapper, sessionsLength && classes.filledList)}>
            {sessionsLength && !loading
              ? <Sessions />
              : <SearchFeedback />
            }
          </div>
        </section>
      </Container>
    </>
  )
}
