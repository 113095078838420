import { useSelector, useDispatch } from '../../hooks/redux'
import cn from 'classnames'
import WaveSurfer from 'wavesurfer.js'
import { FC, memo, useCallback, useMemo } from 'react'
import Scrollbar from 'react-scrollbars-custom'
import { useState, useEffect } from 'react'
import { filter } from '../../functions'
import { TtsFilters } from './ttsFilters/ttsFilters'
import { urlSynthesis } from '../../config'
import { useMediaQuery } from 'react-responsive'
import { ITtsMessagesGroup } from '../../@types/tts'
import { NoResults } from '../../components/noResults'
import { hasSSMLMarkup } from '../../functions/filter'
import { EmotionListConfig } from './config'
// import { initialVoices } from '../../store/slices/tts/config'
import classes from '../../styles/tts/ttsMessages.module.scss'
import { CustomIcon } from '../../components/customIcon/customIcon'
import scrollClasses from '../../styles/customScrollbar.module.scss'
import { convertFromMsToSec, copyText } from '../../functions/common'
import { PortalTooltip } from '../../components/tooltip/portalTooltip'
import asrMessagesClasses from '../../styles/asr/asrMessage.module.scss'
import { PlayerV2, THandlePlayerChangeProps } from '../../components/player/playerV2'
import { ttsRemoveMessage, ttsSetAudio, ttsShowHideMessage } from '../../store/slices/tts/ttsSlice'
import { Skeleton } from '../../components/skeleton'
import defaultUser from '../../assets/defaultUser.png'


const TtsMessagesGroup: FC<{ messages: ITtsMessagesGroup }> = memo(({ messages }) => {
  const dispatch = useDispatch()
  const initialVoices = useSelector((state) => state.tts.voices)
  const listFilters = useSelector((state) => state.tts.listFilters)
  const currentAudioId = useSelector((state) => state.tts.currentAudio.id)
  const [containerWidth, setContainerWidth] = useState(0)
  const isOpened = messages.isOpened
  const id = messages.id
  const text = messages.text

  const showHideMessage = () => {
    dispatch(ttsShowHideMessage({ id }))
  }

  const removeMessage = () => {
    dispatch(ttsRemoveMessage({ id }))
  }

  const handlerCopyText = async () => {
    const textToCopy = text
    await copyText(textToCopy)
  }

  const handleAudioChange = useCallback(
    ({ currentTime }: THandlePlayerChangeProps) => {
      dispatch(ttsSetAudio({ currentTime, id }))
    },
    [dispatch, id]
  )

  const handlePlay = (waveSurfer: WaveSurfer) => {
    const totalTime = waveSurfer.current?.getDuration()
    const currentTime = waveSurfer.current?.getCurrentTime()

    dispatch(ttsSetAudio({ currentTime, totalTime, id }))
  }

  const voices = useMemo(() => {
    let arr = messages.voices

    if (!!listFilters.language) {
      arr = arr.filter((item) => item.languages.length === 0 || item?.languages?.some((lang) => listFilters.language.includes(lang)))
    }

    if (!!listFilters.sex) {
      arr = arr.filter((item) => !item.sex || item?.sex === listFilters.sex)
    }

    return arr
  }, [messages, listFilters])

  useEffect(() => {
    const handleResize = () => {
      const parentElement = document.getElementById('parentId')
      if (parentElement) {
        const width = parentElement.offsetWidth
        setContainerWidth(width)
      }
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return (
    <div className={classes.ttsMessageItem}>
      <div className={classes.ttsMessageItemHeader}>
        <div className={classes.ttsVoiceInfo}>
          <button onClick={showHideMessage}>
            <CustomIcon icon={isOpened ? 'arrowUp' : 'arrowDown'} />
          </button>
          <div className={cn(classes.ttsMessageText, !isOpened && classes.ttsMessageTextHidden)}>{text}</div>
        </div>
        <div className={classes.ttsMessagePlayerWrapper}>
          {!isOpened && (
            <div className={classes.ttsMessagePreview}>
              {voices.slice(0, 3).map((voice) => {
                // const voice = initialVoices.find((el) => el.name === item.voice)

                return (
                  <div
                    key={voice.voice}
                    className={cn(
                      classes.ttsAvatar,
                      voice?.sex === 'male' ? classes.male : classes.female,
                      classes.ttsMessagePreviewItem
                    )}
                  >
                    <img src={voice?.avatarUrl || defaultUser} alt="" />
                  </div>
                )
              })}
            </div>
          )}
          <div className={classes.ttsMessageСrossBtn}>
            <button onClick={removeMessage}>
              <CustomIcon icon="cross" />
            </button>
          </div>
        </div>
      </div>
      {isOpened && (
        <div className={classes.ttsMessageContent}>
          <div className={classes.ttsMessageSettingsPanel}>
            <div className={classes.ttsMessageSettings}>
              <span className={classes.ttsMessageSettingsItem}>Тон {messages.pitch}</span>
              <span className={classes.ttsMessageSettingsItem}>Скорость {messages.rate}</span>
              <span className={classes.ttsMessageSettingsItem}>Громкость {messages.volume}</span>
            </div>
            <p className={asrMessagesClasses.asrResultBodyTitle} onClick={handlerCopyText}>
              Копировать текст
              <CustomIcon icon="copy" />
            </p>
          </div>
          <div>
            {voices.length > 0
              ? voices.map((item, idx) => {
                const generationTime = convertFromMsToSec(item.generationTime, true)
                const voice = initialVoices.find((el) => el.name === item.voice)
                const audioUrl = urlSynthesis + item.response_audio_url
                const avatar = voice?.avatar || defaultUser

                return (
                  <div key={voice?.name + '_' + idx} className={classes.ttsVoiceMessageItem}>
                    <div className={classes.ttsVoiceAvatarWrapper}>
                      <div className={cn(classes.ttsAvatar, voice?.sex === 'male' ? classes.male : classes.female)}>
                        {avatar ? (
                          <img src={avatar} alt="" />
                        )
                          : <Skeleton type='avatar' />
                        }
                      </div>
                      <span>{voice?.title || 'Undefined voice'}</span>
                      {voice?.emotion && (
                        <PortalTooltip
                          stayTooltipOnHover={true}
                          classes={{ childrenWrapperClassName: classes.ttsModalTooltip }}
                          placement="top-middle"
                          showOnMobile
                          tooltipContent={EmotionListConfig.find((el) => el.name === item.emotion)?.tittle || item.emotion}
                        >
                          <img
                            width={20}
                            height={20}
                            src={EmotionListConfig.find((el) => el.name === item.emotion)?.url || EmotionListConfig.find((el) => el.name === "undefined")?.url}
                            alt={item.emotion}
                          />
                        </PortalTooltip>
                      )}
                    </div>
                    <div className={classes.ttsGenerationTime}>{generationTime} секунд</div>
                    <div id="parentId" className={classes.ttsMessagePlayerWrapper}>
                      <PlayerV2
                        stopPlayer={currentAudioId !== id}
                        handleOnSeek={handleAudioChange}
                        handleChange={handleAudioChange}
                        handlePlay={handlePlay}
                        url={audioUrl}
                        containerWidth={containerWidth}
                      />
                    </div>
                    <div className={classes.ttsMessageBtn}>
                      <a download="audio" href={audioUrl} rel="noreferrer" target="_blank">
                        <CustomIcon icon="download" />
                      </a>
                    </div>
                  </div>
                )
              })
              : null}
          </div>
        </div>
      )}
    </div>
  )
})

export const TtsMessages = () => {
  const ttsMessages = useSelector((state) => state.tts.messages)
  const listFilters = useSelector((state) => state.tts.listFilters)
  const isLaptopOrMobile = useMediaQuery({ query: '(max-width: 970px)' })

  const messages = useMemo(() => {
    let arr = ttsMessages

    if (!!listFilters.searchString) {
      arr = arr.filter((item) => filter(item.text, listFilters.searchString))
    }

    if (!!listFilters.sanitize) {
      if (listFilters.sanitize === 'ssml') {
        arr = arr.filter((item) => hasSSMLMarkup(item.text))
      } else if (listFilters.sanitize === 'normalization') {
        arr = arr.filter((item) => !hasSSMLMarkup(item.text))
      }
    }

    if (!!listFilters.language) {
      arr = arr.filter((item) =>
        Array.isArray(item?.voices) && item.voices.some((voice) =>
          voice.languages.length === 0 || voice?.languages?.some((lang) => listFilters.language.includes(lang))
        )
      )
    }

    if (!!listFilters.sex) {
      arr = arr.filter((item) =>
        Array.isArray(item?.voices) && item.voices.some((voice) => !voice.sex || voice?.sex === listFilters.sex)
      )
    }


    return arr
  }, [ttsMessages, listFilters])

  return (
    <div className={cn(classes.ttsMessagesWrapper, !!ttsMessages?.length && classes.ttsMessagesWrapperActive)}>
      {!isLaptopOrMobile && <TtsFilters />}
      <div className={classes.ttsMessageItemsWrapper}>
        {!isLaptopOrMobile ? (
          <Scrollbar noScrollX className={scrollClasses.customScrollbar}>
            {!!messages?.length && messages.map((item) => <TtsMessagesGroup messages={item} key={item.id} />)}
            {ttsMessages.length && !messages.length && <NoResults />}
          </Scrollbar>
        ) : (
          <>
            {!!messages?.length && messages.map((item) => <TtsMessagesGroup messages={item} key={item.id} />)}
            {ttsMessages.length && !messages.length && <NoResults />}
          </>
        )}
      </div>
    </div>
  )
}
