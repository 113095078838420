import classes from '../../../styles/tts/ttsSidebar.module.scss'
import { SidebarVoices } from './sidebarVoices'
import { 
  ChangeEvent, 
  useMemo, 
  useState, 
  createContext, 
  Dispatch, 
  SetStateAction, 
  useEffect
} from 'react'
import { CustomButton } from '../../../components/customButton'
import { useDispatch, useSelector } from '../../../hooks/redux'
import { getTtsConfig, ttsSend } from '../../../store/slices/tts/async'
import { Loader } from '../../../components/loader'
import { SidebarButtons } from './sidebarButtons'
import cn from 'classnames'
import { unwrapResult } from '@reduxjs/toolkit'
import { ITtsVoiceMessage } from '../../../@types/tts'
import { IError } from '../../../@types/user'
import { logout } from '../../../store/slices/user/userSlice'
import { addNotify } from '../../../store/slices/notify/notifySlice'
import { useNavigate } from 'react-router-dom'
import { TtsSidebarHeader } from './sidebarHeader'
import { PortalTooltip } from '../../../components/tooltip/portalTooltip'
import { v4 } from 'uuid'

export const TextAreaCtx = createContext<{ setTextareaValue: Dispatch<SetStateAction<string>> }>({
  setTextareaValue: (p) => '',
})

export const TtsSidebar = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [value, setValue] = useState('')
  const ttsSettings = useSelector((state) => state.tts.settings)
  const ttsRequestLoading = useSelector((state) => state.tts.loading)
  const voices = useSelector((state) => state.tts.voices)
  const isSidebarSmall = useSelector((state) => state.tts.messages.length > 0)

  const ttsVoicesPicked = useMemo(() => voices.filter((voice) => voice.picked).map((voice) => voice.name), [voices])

  const btnDisabled = !ttsVoicesPicked.length || ttsRequestLoading || !value

  const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => setValue(e.target.value)

  const sendTtsData = async () => {
    if (ttsRequestLoading || btnDisabled) return

    try {
      const id = Date.now() + v4()
      const res: ITtsVoiceMessage[] | IError[] = await Promise.all(
        ttsVoicesPicked.map(async (voice) => {
          const currentVoice = voices.find((item) => item.name === voice)

          const data = {
            text: value,
            ...{ ...ttsSettings, id, emotion: currentVoice?.emotion || '' },
          }

          const voiceData = await dispatch(ttsSend({ ...data, voice, id }))
          return unwrapResult({ ...voiceData, emotion: currentVoice?.emotion || '' })
        })
      )

      if ((res as IError[])?.[0]?.response_code === 2) {
        navigate('/')
        dispatch(logout())
        dispatch(addNotify({ text: (res as IError[])?.[0].msg }))
      }
    } catch (e: any) {
      dispatch(addNotify({ text: e.message }))
    }
  }

  useEffect(() => {
      dispatch(getTtsConfig())
  }, [])

  return (
    <TextAreaCtx.Provider value={{ setTextareaValue: setValue }}>
      <div className={cn(classes.ttsSidebarWrapper, isSidebarSmall && classes.ttsSidebarWrapperSmall)}>
        <TtsSidebarHeader />
        <SidebarVoices />
        <textarea
          className={classes.ttsTextarea}
          value={value}
          onChange={handleChange}
          placeholder="Привет! Этот текст можно превратить в речь с помощью наших технологий."
        />
        <div className={classes.ttsSidebarFooter}>
          <SidebarButtons />
          <PortalTooltip tooltipContent={btnDisabled ? 'Нельзя синтезировать текст без шаблона текста' : ''}>
            <CustomButton onClick={sendTtsData} className={cn(classes.sanitizeBtn, btnDisabled && classes.disabled)}>
              {ttsRequestLoading ? <Loader size={20} color="#fff" /> : 'Синтезировать'}
            </CustomButton>
          </PortalTooltip>
        </div>
      </div>
    </TextAreaCtx.Provider>
  )
}
