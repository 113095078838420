import { Container } from "../../components/container"
import { AdminHeader } from "../../layouts/admin/adminHeader"
import { UsersList } from "../../layouts/admin/users/usersList"
import { AdminModal } from "../../layouts/admin/modal/adminModal"
import { ConfirmationModal } from "../../layouts/admin/modal/confirmationModal"
import { DeleteUserModal } from "../../layouts/admin/modal/deleteUserModal"
import { AutocompleteOffFields } from "../../components/textField/autocompleteOffFields"
import { useMediaQuery } from "react-responsive"
import classes from "../../styles/admin/adminPage.module.scss"


export const AdminPage = () => {
  const isLaptopOrMobile = useMediaQuery({ query: '(max-width: 1070px)' })

  return (
    <>
      <Container>
        <section >
          <div className={classes.adminPageSection}>
            {/* Компонент для обхода автозаполнения */}
            <AutocompleteOffFields />

            {!isLaptopOrMobile && <AdminHeader />}
            <div className={classes.usersListWrapper}>
              <UsersList />
            </div>
            <AdminModal />
            <ConfirmationModal />
            <DeleteUserModal />
          </div>

        </section>
      </Container>
    </>
  )
}
