import { CustomIcon } from '../../components/customIcon/customIcon'
import classes from '../../styles/statisticsConsole/searchFeedback.module.scss'
import { useSelector } from '../../hooks/redux'
import { Loader } from '../../components/loader'
import cn from 'classnames'


export const SearchFeedback = () => {
    const loading = useSelector((state) => state.console.loading)
    const error = useSelector((state) => state.console.error)
    const stats = useSelector((state) => state.console.stats)
    const sessionsLength = useSelector((state) => state.console.sessions.length)
    const errorText = useSelector((state) => state.console.errorText)
    const notFound = sessionsLength === 0

    const renderContent = () => {
        switch (true) {
            case loading:
                return (
                    <>
                        <Loader size={120} />
                        <span className={classes.feedbackTitle}>Загрузка...</span>
                    </>
                )
            case error:
                return (
                    <>
                        <div className={classes.feedbackIconWrapper}>
                            <CustomIcon icon='consoleError' />
                        </div>
                        <span className={classes.feedbackTitle}>Произошла ошибка</span>
                        <span className={classes.feedbackSubtitle}>{errorText}</span>
                    </>
                )
            case notFound:
                return (
                    <>
                        <CustomIcon icon='noResults' />
                        <span className={classes.feedbackTitle}>Ничего не найдено</span>
                    </>
                )
            case stats === null:
                return (
                    <>
                        <CustomIcon icon='filterSettingsIcon' />
                        <span className={cn(classes.feedbackTitle, classes.feedbackSecondaryTitle)}>Выберите параметры для поиска</span>
                    </>
                )
        }
    }

    return (
        <div className={classes.feedbackContainer}>
            <div className={classes.feedbackContent}>{renderContent()}</div>
        </div>
    )
}
