import flirtEmoji from '../../assets/images/emoji/flirt.png'
import disappointmentEmoji from '../../assets/images/emoji/disappointment.png'
import sadnessEmoji from '../../assets/images/emoji/sadness.png'
import fearEmoji from '../../assets/images/emoji/fear.png'
import curiosityEmoji from '../../assets/images/emoji/curiosity.png'
import surpriseEmoji from '../../assets/images/emoji/surprise.png'
import disgustEmoji from '../../assets/images/emoji/disgust.png'
import evilEmoji from '../../assets/images/emoji/evil.png'
import goodEmoji from '../../assets/images/emoji/good.png'
import neutralEmoji from '../../assets/images/emoji/neutral.png'
import undefinedEmoji from '../../assets/images/emoji/undefinedEmotion.png'


export const examplesConfig = [
  {
    title: 'Новостная сводка',
    phrases: [
      '2022 (две ты́сячи два́дцать второ́й) год по григорианскому календарю — невисокосный год, начинающийся в субботу. Это 2022 год нашей эры, 2 год 3-го десятилетия XXI века 3-го тысячелетия, 3 год 2020-х годов.',
      'В последние дни декабря принято вспоминать основные события уходящего года. 2022-й для многих вошел в историю как год, когда случилось все, что не могло случиться. Помимо больших и долгожданных спортивных событий – Олимпиады в Пекине и чемпионата мира по футболу в Катаре, это начало конфликта между Россией и Украиной, смерть королевы Великобритании Елизаветы II, протесты в Иране и Китае, революция в Twitter и не только',
      'Добрый день! В этом выпуске новостей мы расскажем о последних событиях в мире политики, экономики и культуры. Начнём с главного.',
      'Сегодня в мире: новые технологические прорывы обещают изменить нашу повседневную жизнь. Подробности в нашем специальном репортаже...',
      'Внимание! Свежие новости спорта: наша сборная одержала победу на международном турнире. Подробности и интервью с тренером в нашем выпуске...',
      'Экономический обзор: эксперты обсуждают последние тенденции в мировой экономике. Не пропустите аналитический отчет в 20:00...',
      'Последние новости культуры: знаменитый музей анонсировал выставку редких произведений. Узнайте больше о предстоящем событии у нас на канале...',
    ],
  },
  {
    title: 'Пословицы и поговорки',
    phrases: [
      'На менеджера надейся, а сам не плошай.',
      'Код реф+акторингом не испортишь.',
      'Без труда не выловишь и рыбку из пруд+а.',
      'Не зная броду, не суйся в воду.',
      'Не всё то золото, что блестит.',
      'Дружба крепка не силой, а правдой.',
      'Лучше поздно, чем никогда.',
      'На безрыбье и рак рыба.',
    ],
  },
  {
    title: 'Простой приветственный текст',
    phrases: [
      'Добрый день! Вас приветствует компания «Лаборатория Наносемантика», наберите добавочный номер сотрудника или дождитесь ответа оператора.',
      'Добро пожаловать на наш сайт! Мы рады предложить вам широкий ассортимент продукции на любой вкус и цвет.',
      'Здравствуйте! Спасибо, что выбрали нас. Ваш персональный менеджер всегда готов помочь вам с любым вопросом.',
      'Привет! Рады видеть вас в нашем онлайн-магазине. Не пропустите наши специальные предложения и скидки!',
      'Вас приветствует команда Наносемантика! Если у вас возникнут вопросы, мы всегда к вашим услугам.',
      'Приветствуем вас! Ваше удобство и удовлетворение - наш главный приоритет. Приятных покупок!',
    ],
  },
  {
    title: 'Пример с ssml разметкой',
    phrases: [
      '<speak>Нам любые <sub alias="д+ороги дор+оги">дороги дороги.</sub></speak>',
      '<speak>Добро пожаловать на наш сайт. <break time="1000ms"/> Мы рады видеть вас!</speak>',
      '<speak>Сегодняшняя погода: <say-as interpret-as="date" format="dm">18.01.2024</say-as>. Ожидается солнечно.</speak>',
    ],
  },
]

export const ttsSettingsConfig = [
  {
    name: 'Громкость',
    value: 'volume',
    min: -12,
    max: 12,
    step: 0.5,
  },
  {
    name: 'Тон',
    value: 'pitch',
    min: 0.75,
    max: 1.5,
    step: 0.05,
  },
  {
    name: 'Скорость',
    value: 'rate',
    min: 0.5,
    max: 2,
    step: 0.1,
  },
]

export const EmotionListConfig = [
  {
    name: 'flirt',
    tittle: 'Флирт',
    url: flirtEmoji,
  },
  {
    name: 'disappointment',
    tittle: 'Разочарование',
    url: disappointmentEmoji,
  },
  {
    name: 'sadness',
    tittle: 'Грусть',
    url: sadnessEmoji,
  },
  {
    name: 'fear',
    tittle: 'Испуг',
    url: fearEmoji,
  },
  {
    name: 'curiosity',
    tittle: 'Любопытство',
    url: curiosityEmoji,
  },
  {
    name: 'surprise',
    tittle: 'Удивление',
    url: surpriseEmoji,
  },
  {
    name: 'disgust',
    tittle: 'Раздражение',
    url: disgustEmoji,
  },
  {
    name: 'anger',
    tittle: 'Злость',
    url: evilEmoji,
  },
  {
    name: 'happiness',
    tittle: 'Радость',
    url: goodEmoji,
  },
  {
    name: 'neutral',
    tittle: 'Нейтральная',
    url: neutralEmoji,
  },
  {
    name: 'undefined',
    title: 'undefined',
    url: undefinedEmoji
  }
]
